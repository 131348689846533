import React from 'react';
import axios from 'axios';

import { API_PATH_SAVEOFFERME, API_PATH_SAVEOFFERME_HANZI, API_PATH_SAVEOFFERME_CHENGYU } from './constants';



class OfferMe extends React.Component {
	constructor(props) {
		super(props);

		const randomOfferMe = Math.floor(9*(new Date().getMilliseconds()*0.0005+0.5*Math.random()));
		this.state = {hasOfferedMe: false, randomOfferMe: randomOfferMe};
	}

	offerme(e, offerMeId) {
		// Save the offerMeId in the database
		if(this.props.userId !== null) {
			axios({
			 method: 'post',
			 url: this.props.testType==='vocab' ? `${API_PATH_SAVEOFFERME}` 
			 									: (this.props.testType==='hanzi' ? `${API_PATH_SAVEOFFERME_HANZI}` : `${API_PATH_SAVEOFFERME_CHENGYU}`),
			 headers: {'content-type': 'application/json'},
			 data: {userId: this.props.userId, offerMeId: offerMeId}
			})
			.then(result => {
			 this.setState({hasOfferedMe: true});
			})
			.catch(error => {});
		}
	}

	render() {  
		const offerMeSimp = ['Offer me some 饺子 🥟', 'Offer me some 月饼 🥮', 'Offer me a cup of 咖啡 ☕️', 'Offer me a cup of 茶 🍵', 'Offer me a 幸运饼干 🥠', 'Offer me some 面条 🍜', 'Offer me some 米饭 🍚', 'Offer me a 甜甜圈 🍩', 'Offer me a 饼干 🍪'];
		const offerMeTrad = ['Offer me some 餃子 🥟', 'Offer me some 月餅 🥮', 'Offer me a cup of 咖啡 ☕️', 'Offer me a cup of 茶 🍵', 'Offer me a 幸運餅乾 🥠', 'Offer me some 麵條 🍜', 'Offer me some 米飯 🍚', 'Offer me a 甜甜圈 🍩', 'Offer me a 餅乾 🍪'];
		const offerMeMessage = this.props.hanziType==='trad' ? offerMeTrad[this.state.randomOfferMe] : offerMeSimp[this.state.randomOfferMe];

		return (
		<div className="alert alert-secondary">
		   <strong>HSKlevel is free</strong>
		   <br/>
		   <button className="btn btn-light" style={{margin:'5px 5px 5px 5px'}} type="button">
		     <a href="https://paypal.me/fpierrepaty" role="button" className="alert-link"
		     	onClick={(e) => this.offerme(e, this.state.randomOfferMe)}
		     	target="_blank" rel="noreferrer noopener" style={{fontSize:'20px'}}>
		         {offerMeMessage}
		     </a>
		   </button>
		   {this.state.hasOfferedMe && <em><br/>{this.props.hanziType==='trad' ? '非常感謝' : '非常感谢'} <span role="img" aria-label="love-you">😍</span></em>}
		 </div>
		);
	}
}


export default OfferMe
import React from 'react';
import axios from 'axios';

import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveLine } from '@nivo/line';
import { BoxLegendSvg } from "@nivo/legends";

import { API_PATH_SAVE_RESULTS_CHENGYU,
  API_PATH_GET_PAST_RESULTS_CHENGYU,
  API_PATH_REGISTER,
  API_PATH_SAVEFEEDBACK_CHENGYU,
  API_PATH_SAVEAFFILIATE_CHENGYU,
  EVOLUTION_GRAPH_COLORS } from '../constants';

import OfferMe from '../OfferMe';
import ShareButton from './ShareButton';
import RestartButton from '../RestartButton';



class DoughnutChart extends React.Component {

  render() {
    let data = [{
      "id": "Chengyu to learn",
      "value": this.props.total-this.props.x,
    },{
      "id": "Known chengyu",
      "value": this.props.x,
    }];

  const CustomLayerComponent = (myProps) => (layerProps) => {
    const { centerX, centerY } = layerProps;
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "26px",
          fontWeight: "100"
        }}
      >
        {myProps}
      </text>
    );
  };
  const CustomLayerComponent2 = (myProps) => (layerProps) => {
    const { centerX, centerY } = layerProps;
    return (
      <text
        x={centerX}
        y={centerY-30}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "20px",
          fontWeight: "400"
        }}
      >
        {myProps}
      </text>
    );
  }; 
    return (
      <div className="col-xs-12 col-sm-6 col-md-4" style={{minHeight:'200px', minWidth:'200px'}}>
        <ResponsivePie
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        startAngle={90}
        endAngle={450}
        innerRadius={0.80}
        fit={false}
        colors={{ scheme: 'paired' }}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        isInteractive={false}
        layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends', CustomLayerComponent(this.props.x+' / '+this.props.total), CustomLayerComponent2(this.props.title)]}
    />
      </div>
    );
  }
}


class ChengyuChartHSK extends React.Component {
  render() {
    const numberChengyu = [0, 0, 0, 0, 1, 113];
    const charts = [5,6].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={'HSK '+lvl.toString()}
        x={this.props.chengyuByLevel[lvl-1]}
        total={numberChengyu[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'400px'}}>
        {charts}
      </div>
    );
  }
}

class ChengyuChartNewHSK extends React.Component {
  render() {
    const numberChengyu = [0, 2, 0, 1, 1, 3, 346];
    const titles = ['HSK 1', 'HSK 2', 'HSK  3', 'HSK 4', 'HSK 5', 'HSK 6', 'HSK 7-9'];
    const charts = [2,4,5,6,7].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={titles[lvl-1]}
        x={this.props.chengyuByLevel[lvl-1]}
        total={numberChengyu[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'600px'}}>
        {charts}
      </div>
    );
  }
}

class ChengyuChartTOCFL extends React.Component {
  render() {
    const numberChengyu = [0, 0, 1, 0, 3, 2, 8];
    const titles  = ['Novice 1', 'Novice 2', 'Band A.1', 'Band A.2', 'Band B.1', 'Band B.2', 'Band  C.1'];
    const charts = [3,5,6,7].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={titles[lvl-1]}
        x={this.props.chengyuByLevel[lvl-1]}
        total={numberChengyu[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'600px'}}>
        {charts}
      </div>
    );
  }
}


class EvolutionChart extends React.Component {
  render() {
    
    const data = this.props.chengyuByDate;
    const dates = this.props.dates;

    return (
      <div className="container text-center">
        <div className="row justify-content-center" style={{marginTop:'0px', paddingTop:'10px', minHeight:'70vh', maxHeight:'500px', minWidth: 0, width:'99%'}}>
         <h5>Number of Chengyu</h5>
          <div className="col" style={{marginTop:'0px', paddingTop:'10px', minHeight:'70vh', maxHeight:'500px', minWidth: 0, width:'99%'}}>
            <ResponsiveLine
              data={data}
              margin={{ top: 10, right: 5, bottom: 70, left: 90 }}
              xScale={{ format: "%m/%d/%Y %H:%M:%S", type: "time" }}
              xFormat="time:%m/%d/%Y %H:%M:%S"
              yScale={{
                  type: 'linear',
                  min: 1,
                  max: 'auto',
                  stacked: false,
                  reverse: false
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -30,
                  tickValues: 5,
                  format: "%b %Y",
                  legend: '',
                  legendOffset: 60,
                  legendPosition: 'middle'
              }}
              axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -50,
                  legendPosition: 'middle'
              }}
              pointSize={0}
              enableArea={true}
              areaOpacity={1}
              //curve="step"
              //curve="catmullRom"
              isInteractive={false}
              useMesh={false}
              colors={EVOLUTION_GRAPH_COLORS[4]}
              theme={{ axis: {ticks: {text: {fontSize: 20}}}, legends: { text: { fontSize: 20 } } }}
            />
          </div>
        </div>
      </div>
    );
  }
}


class Affiliate extends React.Component {

  affiliate(e) {
    // Save the affiliation in the database
    axios({
      method: 'post',
      url: `${API_PATH_SAVEAFFILIATE_CHENGYU}`,
      headers: {'content-type': 'application/json'},
      data: {userId: this.props.userId, affiliateBrand:this.props.affiliateBrand}
    })
    .catch(error => {});
  }

  render() {
    return (
      <>
        {this.props.affiliateBrand === 'maayot' &&
          <div className="alert alert-primary">
            <strong>Our special recommendation for you:</strong>
            <button className="btn btn-primary" style={{margin:'5px 5px 5px 5px', fontSize:'22px'}} type="button">
                <a href={"https://www.maayot.com?via=hsk"} role="button"
                  onClick={(e) => this.affiliate(e)} target="_blank" rel="noreferrer noopener" style={{fontSize:'22px', color:'white'}}>
                     Click here to boost your <em>Chinese reading</em> with stories
                </a>
            </button>
          </div>
        }
        {this.props.affiliateBrand === 'fluentu' &&
          <div className="alert alert-primary">
            <strong>Our special recommendation for you:</strong>
            <button className="btn btn-primary" style={{margin:'5px 5px 5px 5px', fontSize:'22px'}} type="button">
                <a href={"https://www.fluentu.com/?rfsn=5723753.c21670b&subid=results"} role="button"
                  onClick={(e) => this.affiliate(e)} target="_blank" rel="noreferrer noopener" style={{fontSize:'22px', color:'white'}}>
                     Click here to learn Chinese with real-world videos
                </a>
            </button>
          </div>
        }
      </>
    );
  }
}


class ResultsChengyu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      knownChengyu: 0,
      chengyuByLevel: [0,0,0,0,0,0],
      chengyuByNewLevel: [0,0,0,0,0,0,0],
      chengyuByTOCFLLevel: [0,0,0,0,0,0,0],
      isNewUser: true,
      dates: [],
      chengyuByDate: [],
      givenFeedback: null,
      showAffiliate: false
    };

    /* Compute the number of known words
    Save the results in the database */
    axios({
      method: 'post',
      url: `${API_PATH_SAVE_RESULTS_CHENGYU}`,
      headers: {'content-type': 'application/json'},
      data: {
        userId: this.props.userId,
        userLevel: this.props.userLevel
      }
    })
    .then(result => {
        this.setState({
          knownChengyu: result.data.knownChengyu,
          chengyuByLevel: result.data.chengyuByLevel,
          chengyuByNewLevel: result.data.chengyuByNewLevel,
          chengyuByTOCFLLevel: result.data.chengyuByTOCFLLevel
        });
        
        /* Check if the user is new, and if not, return its past results */
        axios({
          method: 'post',
          url: `${API_PATH_GET_PAST_RESULTS_CHENGYU}`,
          headers: {'content-type': 'application/json'},
          data: {
            userEmail: this.props.userEmail
          }
        })
        .then(result => {
            const today = new Date();
            const firstseen = new Date(result.data.dates[0]);
            this.setState({
              isNewUser: result.data.isNewUser,
              dates: result.data.dates,
              chengyuByDate: result.data.chengyuByDate,
              showAffiliate: today-firstseen < 1000*3600*24
            });
          })
        .catch(error => {
            this.setState({error:error})
          });
      })
    .catch(error => {
        this.setState({error:error})
      });

  }


  feedback(e, feedbackAnswer) {
    // Save the feedback in the database
    feedbackAnswer = (feedbackAnswer==='yes');
    axios({
      method: 'post',
      url: `${API_PATH_SAVEFEEDBACK_CHENGYU}`,
      headers: {'content-type': 'application/json'},
      data: {userId: this.props.userId, feedbackAnswer: feedbackAnswer}
    })
    .then(result => {
      this.setState({givenFeedback: feedbackAnswer});
    })
    .catch(error => {});
  }


  render() {
    const TOCFLTitles = ['Novice 1', 'Novice 2', 'Band A.1', 'Band A.2', 'Band B.1', 'Band B.2', 'Band C.1'];
    const newHSKTitles = ['HSK 1', 'HSK 2', 'HSK 3', 'HSK 4', 'HSK 5', 'HSK 6', 'HSK 7-9'];
    //const affiliateBrand = (this.props.userId % 2 === 0) ? 'maayot' : 'fluentu';
    //const affiliateBrand = 'maayot';

    return (
      <div className="container">

        <div className="alert span5 alert-primary">
          <h4>You know <strong>{this.state.knownChengyu===5650 ? "more than 5650" : this.state.knownChengyu}</strong> chengyu</h4>
            <em>(Scroll down to see all the details about your level)</em>

          <ShareButton userId={this.props.userId} userKnownChengyu={this.state.knownChengyu} />

          <div>Do you think the test is accurate for you?
            {this.state.givenFeedback === null &&
              <div>
                <button className="btn btn-primary" style={{margin:'0 5px 0 5px'}} type="button" onClick={(e) => this.feedback(e, 'yes')} disabled={this.state.givenFeedback!==null}>Yes</button>
                <button className="btn btn-primary" style={{margin:'0 5px 0 5px'}} type="button" onClick={(e) => this.feedback(e, 'no')} disabled={this.state.givenFeedback!==null}>No</button>
              </div>
            }
            {this.state.givenFeedback && <span><br/><em>Great!</em> <span role="img" aria-label="happy">☺️</span></span>}
            {this.state.givenFeedback===false &&
              <><br/>
                <em> Uh-oh </em><span role="img" aria-label="oops">😬</span><em>, try to retake the test</em>
                <RestartButton
                  userEmail={this.props.userEmail}
                  hanziType={this.props.hanziType}
                  onRestartTheTest={this.props.onRestartTheTest}
                  testType={'chengyu'}
                />
              </>
            }
          </div>
        </div>

        {/*
          this.state.showAffiliate &&
          <Affiliate userId={this.props.userId} affiliateBrand={affiliateBrand} />
          */
        }

        
        <div className="alert alert-info">
          <strong>You can also test your vocabulary and characters</strong>
          <br />
          <em>Click only when you{"'"}re finished checking your results</em>
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'vocab'}
            title={'Take the vocabulary test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'hanzi'}
            title={'Take the characters test'}
          />
        </div>

        <OfferMe userId={this.props.userId} hanziType={this.props.hanziType} testType='chengyu' />
        
        
        <h2 style={{marginTop:'70px'}}>1. Your chengyu knowledge by HSK 2.0 level</h2>
        {this.props.hanziType === 'trad' && <p><em><span role="img" aria-label="warning">⚠️</span>The HSK test uses simplified Chinese characters!</em></p>}
        <p>There is no chengyu in the HSK 1,2,3,4 levels, only one in the HSK 5 level and 113 chengyu are from the HSK 6 level.</p>
        <ChengyuChartHSK chengyuByLevel={this.state.chengyuByLevel} />


        <h2 style={{marginTop:'70px'}}>2. Your chengyu knowledge by HSK 3.0 level</h2>
        {this.props.hanziType === 'trad' && <p><em><span role="img" aria-label="warning">⚠️</span>The HSK test uses simplified Chinese characters!</em></p>}
        <p>Most chengyu are from the HSK 7-9 level.</p>
        <ChengyuChartNewHSK chengyuByLevel={this.state.chengyuByNewLevel} />


        <h2 style={{marginTop:'70px'}}>3. Your chengyu knowledge by TOCFL level</h2>
        {this.props.hanziType === 'simp' && <p><em><span role="img" aria-label="warning">⚠️</span>The TOCFL test uses traditional Chinese characters!</em></p>}
        <p>There are very few chengyu in the TOCFL lists.</p>
        <ChengyuChartTOCFL chengyuByLevel={this.state.chengyuByTOCFLLevel} />
        

        {!this.state.isNewUser &&
          <div style={{marginTop:'70px'}}>
          <h2>4. Your progression</h2>
          <EvolutionChart
            dates={this.state.dates}
            chengyuByDate={this.state.chengyuByDate}
          />
          </div>
        }
        <div style={{marginTop:'70px'}}></div>


        {/*
          this.state.showAffiliate &&
          <Affiliate userId={this.props.userId} affiliateBrand={affiliateBrand} />
          */
        }

        
        <div>
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'chengyu'}
            title={'Retake the chengyu test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'vocab'}
            title={'Take the vocabulary test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'hanzi'}
            title={'Take the characters test'}
          />
        </div>

      </div>
    );
  }


}


export default ResultsChengyu

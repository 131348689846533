import React from 'react';
import axios from 'axios';

import { API_PATH_SHARERESULTS } from '../constants';



class ShareButton extends React.Component {
	constructor(props) {
		super(props);

		this.state = {hasShared: false};
	}

	shareResults(e) {
    // Copy to clipboard and save the source of the share in the database
    	//const text = 'HSK level '+this.props.userHSKlevel;

    	let text = 'My Chinese level statistics'
    	text += '\n\n';
    	text += 'I know ' + this.props.userKnownWords + ' words in Chinese';
    	text += '\n\n';
    	text += 'HSK level ' + this.props.userHSKlevel + '/6\n';
		text += '🟩'.repeat(Math.max(0,(this.props.userHSKlevel-1))) + '🟨'.repeat(Math.min(this.props.userHSKlevel, 1)) + '⬛'.repeat((6-this.props.userHSKlevel));
    	text += '\n\n';
    	text += 'New HSK level ' + (this.props.userNewHSKlevel===7 ? '7-9' : this.props.userNewHSKlevel) + '/9\n';
    	text += '🟩'.repeat(Math.max(0,(this.props.userNewHSKlevel-1))) + '🟨'.repeat(this.props.userNewHSKlevel===7 ? 3 : Math.min(this.props.userNewHSKlevel, 1)) + '⬛'.repeat(this.props.userNewHSKlevel===7 ? 0 : (9-this.props.userNewHSKlevel));
    	text += '\n\n';
    	text += 'Take the test on www.hsklevel.com';

    	navigator.clipboard.writeText(text);

	    axios({
	      method: 'post',
	      url: `${API_PATH_SHARERESULTS}`,
	      headers: {'content-type': 'application/json'},
	      data: {userId: this.props.userId}
	    })
	    .then(result => {
		  this.setState({hasShared: true});
		})
	    .catch(error => {});
	}


	render() {  

		return (
		<div>
	        <button className="btn btn-success" style={{margin:'5px 5px 5px 5px', fontSize:'22px'}} type="button"
	        		onClick={(e) => this.shareResults(e)}>
	          Share your results
	        </button>
		    {this.state.hasShared && <p><em>Copied results to clipboard</em></p>}
        </div>
		);
	}
}


export default ShareButton
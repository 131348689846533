import React from 'react';
import axios from 'axios';

import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadar } from '@nivo/radar';
import { ResponsiveLine } from '@nivo/line';
import { BoxLegendSvg } from "@nivo/legends";

import { API_PATH_SAVE_RESULTS,
  API_PATH_GET_PAST_RESULTS,
  API_PATH_SAVEFEEDBACK,
  API_PATH_SAVEAFFILIATE,
  EVOLUTION_GRAPH_COLORS } from '../constants';

import OfferMe from '../OfferMe';
import ShareButton from './ShareButton';
import RestartButton from '../RestartButton';



class DoughnutChart extends React.Component {

  render() {
    let data = [{
      "id": "Words to learn",
      "value": this.props.total-this.props.x,
    },{
      "id": "Known words",
      "value": this.props.x,
    }];

  const CustomLayerComponent = (myProps) => (layerProps) => {
    const { centerX, centerY } = layerProps;
    return (
      <text
        x={centerX}
        y={centerY}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "26px",
          fontWeight: "100"
        }}
      >
        {myProps}
      </text>
    );
  };
  const CustomLayerComponent2 = (myProps) => (layerProps) => {
    const { centerX, centerY } = layerProps;
    return (
      <text
        x={centerX}
        y={centerY-30}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "20px",
          fontWeight: "400"
        }}
      >
        {myProps}
      </text>
    );
  }; 
    return (
      <div className="col-xs-12 col-sm-6 col-md-4" style={{minHeight:'200px', minWidth:'200px'}}>
        <ResponsivePie
        data={data}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        startAngle={90}
        endAngle={450}
        innerRadius={0.80}
        fit={false}
        colors={{ scheme: 'paired' }}
        enableArcLinkLabels={false}
        enableArcLabels={false}
        isInteractive={false}
        layers={['arcLinkLabels', 'arcs', 'arcLabels', 'legends', CustomLayerComponent(this.props.x+' / '+this.props.total), CustomLayerComponent2(this.props.title)]}
    />
      </div>
    );
  }
}


class WordsChartHSK extends React.Component {
  render() {
    const numberWords = [150, 150, 300, 600, 1300, 2500];
    const charts = [1,2,3,4,5,6].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={'HSK '+lvl.toString()}
        x={this.props.wordsByLevel[lvl-1]}
        total={numberWords[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'400px'}}>
        {charts}
      </div>
    );
  }
}

class WordsChartNewHSK extends React.Component {
  render() {
    const numberWords = [500, 772, 973, 1000, 1071, 1140, 5636];
    const titles = ['HSK 1', 'HSK 2', 'HSK  3', 'HSK 4', 'HSK 5', 'HSK 6', 'HSK 7-9'];
    const charts = [1,2,3,4,5,6,7].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={titles[lvl-1]}
        x={this.props.wordsByLevel[lvl-1]}
        total={numberWords[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'600px'}}>
        {charts}
      </div>
    );
  }
}

class WordsChartTOCFL extends React.Component {
  render() {
    const numberWords = [140, 160, 170, 470, 1280, 2150, 2760];
    const titles  = ['Novice 1', 'Novice 2', 'Band A.1', 'Band A.2', 'Band B.1', 'Band B.2', 'Band  C.1'];
    const charts = [1,2,3,4,5,6,7].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={titles[lvl-1]}
        x={this.props.wordsByLevel[lvl-1]}
        total={numberWords[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'600px'}}>
        {charts}
      </div>
    );
  }
}


class FrequencyChartContent extends React.Component {

  render() {

    const theme = {
      axis: {
        ticks: {
          text: {
            fontSize: '20px',
            fontWeight: '400'
          }
        },
      },
      grid: {
        line: {
          stroke: "rgba(0, 0, 0, .30)",
          strokeDasharray: "6 4"
        }
      },
      dots: {
        text: {
          fontSize: '20px',
          fontWeight: '100'
        }
      }
    };

    const frequencies = [{'title': 'Movies & Series', 'value': this.props.frequencyByContent['subtitles']},
                         {'title': 'Blogs', 'value': this.props.frequencyByContent['blogs']},
                         {'title': 'Social Media', 'value': this.props.frequencyByContent['weibo']},
                         {'title': 'Books', 'value': this.props.frequencyByContent['literature']},
                         {'title': 'News', 'value': this.props.frequencyByContent['news']}];
    
    return (
      <div className="container text-center">
        <div className="row justify-content-center" style={{marginTop:'10px', padding:'5%', height:'50vh', minHeight:'300px', minWidth: 0}}>
          <div className="col" style={{minWidth: 0}}>
            <ResponsiveRadar
              margin={{ top: 40, left: 85, right: 85}}
              data={frequencies}
              indexBy="title"
              keys={["value"]}
              colors={"#2490D9"}
              borderWidth={0}
              maxValue={100}
              theme={theme}
              isInteractive={false}
              fillOpacity={0.6}
              dotSize={0}
              dotLabel={function (e){return e.value+'%'}}
              enableDotLabel={true}
              dotLabelYOffset={0}
              gridShape="linear"
            />
          </div>
        </div>
      </div>
    );
  }
}

class EvolutionChart extends React.Component {
  render() {
    //const colors = ["#00254D", "#003670", "#004794", "#0058B7", "#0069DB", "#007BFF", "#c8c8c8"];
    //const colors = ['#1F78B4', '#009AC7', '#00BAC1', '#14D5A7', '#98EB85', '#F9F871', '#DCD9D9'];
    //const colors= ['#14476a', '#045d80', '#007395', '#008aa6', '#00a2b5', '#00bac1', '#DCD9D9'];
    
    const data = this.props.wordsByLevelByDate;
    const dates = this.props.dates;

    const legendTitles = ['HSK 1', 'HSK 2', 'HSK 3', 'HSK 4', 'HSK 5', 'HSK 6', 'Non-HSK']
    let legendHSK = [0,1,2,3,4,5,6].map((lvl) => (
      <div className="col" key={lvl.toString()}>
        <svg height="40px" width="40px">
          <circle cx="20" cy="20" r="9" fill={EVOLUTION_GRAPH_COLORS[lvl]} />
        </svg><br />
        {legendTitles[lvl]}
      </div>
      )
    );

    return (
      <div className="container text-center">
        <div className="row justify-content-center" style={{marginTop:'0px', paddingTop:'10px', minHeight:'70vh', maxHeight:'500px', minWidth: 0, width:'99%'}}>
         <h5>Number of Words</h5>
          <div className="col"  style={{marginTop:'0px', paddingTop:'10px', minHeight:'70vh', maxHeight:'500px', minWidth: 0, width:'99%'}}>
            <ResponsiveLine
              data={data}
              margin={{ top: 10, right: 5, bottom: 70, left: 90 }}
              xScale={{ format: "%m/%d/%Y %H:%M:%S", type: "time" }}
              xFormat="time:%m/%d/%Y %H:%M:%S"
              yScale={{
                  type: 'linear',
                  min: 1,
                  max: 'auto',
                  stacked: false,
                  reverse: false
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -30,
                  tickValues: 5,
                  format: "%b %Y",
                  legend: '',
                  legendOffset: 60,
                  legendPosition: 'middle'
              }}
              axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -50,
                  legendPosition: 'middle'
              }}
              pointSize={0}
              enableArea={true}
              areaOpacity={1}
              //curve="step"
              //curve="catmullRom"
              isInteractive={false}
              useMesh={false}
              colors={EVOLUTION_GRAPH_COLORS}
              theme={{ axis: {ticks: {text: {fontSize: 20}}}, legends: { text: { fontSize: 20 } } }}
            />
          </div>
        </div>
        <div className="container text-center" style={{padding:'0px', paddingTop:'40px'}}>
          <div className="row justify-content-center" style={{margin:'auto', maxWidth:'90%'}}>
            {legendHSK}
          </div>
        </div>
      </div>
    );
  }
}


class Affiliate extends React.Component {

  affiliate(e) {
    // Save the affiliation in the database
    axios({
      method: 'post',
      url: `${API_PATH_SAVEAFFILIATE}`,
      headers: {'content-type': 'application/json'},
      data: {userId: this.props.userId, affiliateBrand:this.props.affiliateBrand}
    })
    .catch(error => {});
  }

  render() {
    return (
      <>
        {this.props.affiliateBrand === 'maayot' &&
          <div className="alert alert-primary">
            <strong>Our special recommendation for you:</strong>
            <button className="btn btn-primary" style={{margin:'5px 5px 5px 5px', fontSize:'22px'}} type="button">
                <a href={"https://www.maayot.com?via=hsk"} role="button"
                  onClick={(e) => this.affiliate(e)} target="_blank" rel="noreferrer noopener" style={{fontSize:'22px', color:'white'}}>
                     Click here to boost your <em>Chinese reading</em> with stories
                </a>
            </button>
          </div>
        }
        {this.props.affiliateBrand === 'fluentu' &&
          <div className="alert alert-primary">
            <strong>Our special recommendation for you:</strong>
            <button className="btn btn-primary" style={{margin:'5px 5px 5px 5px', fontSize:'22px'}} type="button">
                <a href={"https://www.fluentu.com/?rfsn=5723753.c21670b&subid=results"} role="button"
                  onClick={(e) => this.affiliate(e)} target="_blank" rel="noreferrer noopener" style={{fontSize:'22px', color:'white'}}>
                     Click here to learn Chinese with real-world videos
                </a>
            </button>
          </div>
        }
      </>
    );
  }
}


class ResultsWords extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      knownWords: 0,
      knownHanzis: 0,
      HSKlevel: 0,
      newHSKlevel: 0,
      TOCFLlevel: 0,
      wordsByLevel: [0,0,0,0,0,0],
      wordsByNewLevel: [0,0,0,0,0,0],
      wordsByTOCFLLevel: [0,0,0,0,0,0],
      frequencyByContent: {'weibo': 0, 'news': 0, 'subtitles': 0, 'blogs': 0, 'literature': 0},
      isNewUser: true,
      dates: [],
      wordsByLevelByDate: [[],[],[],[],[],[]],
      givenFeedback: null,
      showAffiliate: false
    };

    /* Compute the number of known words
    Save the results in the database */
    axios({
      method: 'post',
      url: `${API_PATH_SAVE_RESULTS}`,
      headers: {'content-type': 'application/json'},
      data: {
        userId: this.props.userId,
        userLevel: this.props.userLevel
      }
    })
    .then(result => {
        this.setState({
          knownWords: result.data.knownWords,
          knownHanzis: result.data.knownHanzis,
          HSKlevel: result.data.HSKlevel,
          newHSKlevel: result.data.newHSKlevel,
          TOCFLlevel: result.data.TOCFLlevel,
          wordsByLevel: result.data.wordsByLevel,
          wordsByNewLevel: result.data.wordsByNewLevel,
          wordsByTOCFLLevel: result.data.wordsByTOCFLLevel,
          frequencyByContent: result.data.frequencyByContent
        });
  
        /* Check if the user is new, and if not, return its past results */
        axios({
          method: 'post',
          url: `${API_PATH_GET_PAST_RESULTS}`,
          headers: {'content-type': 'application/json'},
          data: {
            userEmail: this.props.userEmail
          }
        })
        .then(result => {
            const today = new Date();
            const firstseen = new Date(result.data.dates[0]);
            this.setState({
              isNewUser: result.data.isNewUser,
              dates: result.data.dates,
              wordsByLevelByDate: result.data.wordsByLevelByDate,
              showAffiliate: today-firstseen < 1000*3600*24
            });
          })
        .catch(error => {
            this.setState({error:error})
          });
  })
.catch(error => {
    this.setState({error:error})
  });

  }


  feedback(e, feedbackAnswer) {
    // Save the feedback in the database
    feedbackAnswer = (feedbackAnswer==='yes');
    axios({
      method: 'post',
      url: `${API_PATH_SAVEFEEDBACK}`,
      headers: {'content-type': 'application/json'},
      data: {userId: this.props.userId, feedbackAnswer: feedbackAnswer}
    })
    .then(result => {
      this.setState({givenFeedback: feedbackAnswer});
    })
    .catch(error => {});
  }


  render() {
    const TOCFLTitles = ['Novice 1', 'Novice 2', 'Band A.1', 'Band A.2', 'Band B.1', 'Band B.2', 'Band C.1'];
    const newHSKTitles = ['HSK 1', 'HSK 2', 'HSK 3', 'HSK 4', 'HSK 5', 'HSK 6', 'HSK 7-9'];
    //const affiliateBrand = (this.props.userId % 2 === 0) ? 'maayot' : 'fluentu';
    //const affiliateBrand = 'maayot';

    return (
      <div className="container">

        <div className="alert span5 alert-primary">
          <h4>You know <strong>{this.state.knownWords===20000 ? "more than 20000" : this.state.knownWords}</strong> word{this.state.knownWords>1 ? 's' : ''}</h4>
          {/*<h4>You know <strong>{this.state.knownHanzis}</strong> character{this.state.knownHanzis>1 ? 's' : ''}</h4>*/}
            <em>(Scroll down to see all the details about your level)</em>
            <ul>
              <li><em>HSK 2.0</em>: 
                {this.state.HSKlevel > 0 ? <strong> HSK {this.state.HSKlevel}</strong> : <span> Keep up the good work!</span>}
              </li>
              <li><em>HSK 3.0</em>: 
                {this.state.newHSKlevel ? <strong> {newHSKTitles[this.state.newHSKlevel-1]}</strong> : <span> Keep up the good work!</span>}
              </li>
              <li><em>TOCFL</em>: 
                {this.state.TOCFLlevel ? <strong> TOCFL {TOCFLTitles[this.state.TOCFLlevel-1]}</strong> : <span> Keep up the good work!</span>}
              </li>
            </ul>

          <ShareButton userId={this.props.userId} userHSKlevel={this.state.HSKlevel} userNewHSKlevel={this.state.newHSKlevel} userKnownWords={this.state.knownWords} />

          <div>Do you think the test is accurate for you?
            {this.state.givenFeedback === null &&
              <div>
                <button className="btn btn-primary" style={{margin:'0 5px 0 5px'}} type="button" onClick={(e) => this.feedback(e, 'yes')} disabled={this.state.givenFeedback!==null}>Yes</button>
                <button className="btn btn-primary" style={{margin:'0 5px 0 5px'}} type="button" onClick={(e) => this.feedback(e, 'no')} disabled={this.state.givenFeedback!==null}>No</button>
              </div>
            }
            {this.state.givenFeedback && <span><br/><em>Great!</em> <span role="img" aria-label="happy">☺️</span></span>}
            {this.state.givenFeedback===false &&
              <><br/>
                <em> Uh-oh </em><span role="img" aria-label="oops">😬</span><em>, try to retake the test</em>
                <RestartButton
                  userEmail={this.props.userEmail}
                  hanziType={this.props.hanziType}
                  onRestartTheTest={this.props.onRestartTheTest}
                  testType={'vocab'}
                />
              </>
            }
            </div>
        </div>

        {/*
          this.state.showAffiliate &&
          <Affiliate userId={this.props.userId} affiliateBrand={affiliateBrand} />
          */
        }

        
        <div className="alert alert-info">
          <strong>You can also test your characters and chengyu</strong>
          <br />
          <em>Click only when you{"'"}re finished checking your results</em>
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'hanzi'}
            title={'Take the characters test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'chengyu'}
            title={'Take the chengyu test'}
          />
        </div>

        <OfferMe userId={this.props.userId} hanziType={this.props.hanziType} testType='vocab' />
        
        
        <h2 style={{marginTop:'70px'}}>1. Your vocabulary by HSK 2.0 level</h2>
        {this.props.hanziType === 'trad' && <p><em><span role="img" aria-label="warning">⚠️</span>The HSK test uses simplified Chinese characters!</em></p>}
        {this.state.HSKlevel > 0 && <p><em>Your estimated HSK 2.0 level is: <strong>HSK {this.state.HSKlevel}</strong>.</em></p>}
        {this.state.HSKlevel === 0 && <p><em>Keep up the good work, HSK 1 is within reach!</em></p>}
        <WordsChartHSK wordsByLevel={this.state.wordsByLevel} />


        <h2 style={{marginTop:'70px'}}>2. Your vocabulary by HSK 3.0 level</h2>
        {this.props.hanziType === 'trad' && <p><em><span role="img" aria-label="warning">⚠️</span>The HSK test uses simplified Chinese characters!</em></p>}
        {this.state.newHSKlevel > 0 ?
          <p><em>Your estimated HSK 3.0 level is: <strong>{newHSKTitles[this.state.newHSKlevel-1]}</strong>.</em></p>
          :
          <p><em>Keep up the good work, HSK 1 is within reach!</em></p>}
        <WordsChartNewHSK wordsByLevel={this.state.wordsByNewLevel} />


        <h2 style={{marginTop:'70px'}}>3. Your vocabulary by TOCFL level</h2>
        {this.props.hanziType === 'simp' && <p><em><span role="img" aria-label="warning">⚠️</span>The TOCFL test uses traditional Chinese characters!</em></p>}
        {this.state.TOCFLlevel > 0 ?
          <p><em>Your estimated TOCFL level is: <strong>TOCFL {TOCFLTitles[this.state.TOCFLlevel-1]}</strong>.</em></p>
          :
          <p><em>Keep up the good work, TOCFL Novice is within reach!</em></p>}
        <WordsChartTOCFL wordsByLevel={this.state.wordsByTOCFLLevel} />

        <h2 style={{marginTop:'70px'}}>4. Your vocabulary by type of content</h2>
        <p><em>{"That's how much you can understand from different types of contents:"}</em></p>
        <FrequencyChartContent frequencyByContent={this.state.frequencyByContent} />

        {!this.state.isNewUser &&
          <div style={{marginTop:'70px'}}>
          <h2>5. Your progression</h2>
          <EvolutionChart
            dates={this.state.dates}
            wordsByLevelByDate={this.state.wordsByLevelByDate}
          />
          </div>
        }
        <div style={{marginTop:'70px'}}></div>


        {/*
          this.state.showAffiliate &&
          <Affiliate userId={this.props.userId} affiliateBrand={affiliateBrand} />
          */
        }

        
        <div>
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'vocab'}
            title={'Retake the vocabulary test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'hanzi'}
            title={'Take the characters test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'chengyu'}
            title={'Take the chengyu test'}
          />
        </div>

      </div>
    );
  }


}


export default ResultsWords

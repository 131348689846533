import React from 'react';

import RegisterForm from './Register';

import QuestionsFormWords from './Vocabulary/Questions';
import QuestionsFormHanzi from './Hanzi/Questions';
import QuestionsFormChengyu from './Chengyu/Questions';

import DoyoucontinueWords from './Vocabulary/Doyoucontinue';
import DoyoucontinueHanzi from './Hanzi/Doyoucontinue';
import DoyoucontinueChengyu from './Chengyu/Doyoucontinue';

import SandwichPage from './SandwichPage';

import ResultsWords from './Vocabulary/Results';
import ResultsHanzi from './Hanzi/Results';
import ResultsChengyu from './Chengyu/Results';

import { MAX_NUMBER_QUESTIONS, MAX_NUMBER_QUESTIONS_HANZI, MAX_NUMBER_QUESTIONS_CHENGYU } from './constants';


class LevelTest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      testStatus: 'register' // register, questions, doyoucontinue, sandwich, results, maintenance, about
    };

    this.startTheTest = this.startTheTest.bind(this);
    this.restartTheTest = this.restartTheTest.bind(this);
    this.updateUserAfterAnswer = this.updateUserAfterAnswer.bind(this);
    this.updateUserAfterAnswerHanzi = this.updateUserAfterAnswerHanzi.bind(this);
    this.updateUserAfterAnswerChengyu = this.updateUserAfterAnswerChengyu.bind(this);
    this.continueOrResults = this.continueOrResults.bind(this);
    this.seeResults = this.seeResults.bind(this);
  }

  componentDidMount() {
    this.props.setTestStatus(this.state.testStatus);
  }

  render() {
    const testStatus = this.state.testStatus;
    //const testStatus = 'maintenance';
    const registrationStatus = this.state.registrationStatus;
    let page;
    if(testStatus === 'questions') { // Questions
        switch(registrationStatus) {
          case 'registered':
            page = (this.state.testType === 'vocab') ?
                      <QuestionsFormWords
                        userId={this.state.userId}
                        userLevel={this.state.userLevel} 
                        hanziType={this.state.hanziType}
                        numberOfQuestions={this.state.numberOfQuestions}
                        pastQuestionsList={this.state.pastQuestionsList}
                        maxNumberOfQuestions={this.state.maxNumberOfQuestions}
                        timestampStartTest={this.state.timestampStartTest}
                        updateUserAfterAnswer={this.updateUserAfterAnswer}
                      />
                      :
                      (this.state.testType === 'hanzi') ?
                        <QuestionsFormHanzi
                          userId={this.state.userId}
                          userLevelHandwriting={this.state.userLevelHandwriting} 
                          userLevelPinyin={this.state.userLevelPinyin} 
                          userLevelMeaning={this.state.userLevelMeaning} 
                          hanziType={this.state.hanziType}
                          numberOfQuestions={this.state.numberOfQuestions}
                          pastQuestionsList={this.state.pastQuestionsList}
                          maxNumberOfQuestions={this.state.maxNumberOfQuestions}
                          timestampStartTest={this.state.timestampStartTest}
                          updateUserAfterAnswerHanzi={this.updateUserAfterAnswerHanzi}
                        />
                        :
                        <QuestionsFormChengyu
                          userId={this.state.userId}
                          userLevel={this.state.userLevel} 
                          hanziType={this.state.hanziType}
                          numberOfQuestions={this.state.numberOfQuestions}
                          pastQuestionsList={this.state.pastQuestionsList}
                          maxNumberOfQuestions={this.state.maxNumberOfQuestions}
                          timestampStartTest={this.state.timestampStartTest}
                          updateUserAfterAnswer={this.updateUserAfterAnswerChengyu}
                        />;
            break;
          default:
            page = <RegisterForm onStartTheTest={this.startTheTest} error={registrationStatus} />;
        }
    }
    else if(testStatus === 'doyoucontinue') { // Do you continue the test?
      page = (this.state.testType === 'vocab') ?
                <DoyoucontinueWords
                  onContinueOrResults={this.continueOrResults}
                />
                :
                (this.state.testType === 'hanzi') ?
                  <DoyoucontinueHanzi
                    onContinueOrResults={this.continueOrResults}
                  />
                  :
                  <DoyoucontinueChengyu
                    onContinueOrResults={this.continueOrResults}
                  />;
    }
    else if(testStatus === 'sandwich') { // Sandwich Page For Advertising
      page = <SandwichPage onSeeResults={this.seeResults} />
    }
    else if(testStatus === 'results') { // Results
      page = (this.state.testType === 'vocab') ?
                <ResultsWords
                  userLevel={this.state.userLevel}
                  userId={this.state.userId}
                  userEmail={this.state.userEmail}
                  hanziType={this.state.hanziType}
                  onRestartTheTest={this.restartTheTest}
               />
               :
               (this.state.testType === 'hanzi') ?
                 <ResultsHanzi
                    userLevelHandwriting={this.state.userLevelHandwriting} 
                    userLevelPinyin={this.state.userLevelPinyin} 
                    userLevelMeaning={this.state.userLevelMeaning} 
                    userId={this.state.userId}
                    userEmail={this.state.userEmail}
                    hanziType={this.state.hanziType}
                    onRestartTheTest={this.restartTheTest}
                 />
                 :
                 <ResultsChengyu
                    userLevel={this.state.userLevel}
                    userId={this.state.userId}
                    userEmail={this.state.userEmail}
                    hanziType={this.state.hanziType}
                    onRestartTheTest={this.restartTheTest}
                 />;
    }
    else { // Registration
      page = <RegisterForm onStartTheTest={this.startTheTest} />;
    }

    return (
      <div>
        {page}
      </div>
    );
  }

  /* Register the user and start the test */
  startTheTest(e, registrationStatus, userId, userEmail, hanziType, testType) {
    
    if(testType === 'vocab') {
      // Create the log-likelihood array of length 114, filled with zeros
      let userLevel = [];
      userLevel.length = 114;
      userLevel.fill(0);
      this.setState({
        userLevel: userLevel,
        maxNumberOfQuestions: MAX_NUMBER_QUESTIONS[0]
      });
    }
    else if(testType === 'hanzi') {
      let userLevelHandwriting = [];
      let userLevelPinyin = [];
      let userLevelMeaning = [];
      userLevelHandwriting.length = 106;
      userLevelHandwriting.fill(0);
      userLevelPinyin.length = 106;
      userLevelPinyin.fill(0);
      userLevelMeaning.length = 106;
      userLevelMeaning.fill(0);
      this.setState({
        userLevelHandwriting: userLevelHandwriting,
        userLevelPinyin: userLevelPinyin,
        userLevelMeaning: userLevelMeaning,
        maxNumberOfQuestions: MAX_NUMBER_QUESTIONS_HANZI[0]
      });
    }
    else {
      // Chengyus: Create the log-likelihood array of length 118, filled with zeros (1, 10, 20, 30, 40, 50, 75, 100, 150, ..., 5650)
      let userLevel = [];
      userLevel.length = 119;
      userLevel.fill(0);
      this.setState({
        userLevel: userLevel,
        maxNumberOfQuestions: MAX_NUMBER_QUESTIONS_CHENGYU[0]
      });
    }

    // Disable the header
    this.props.changeShowHeader(false);

    // Change the page to "questions" and create session data
    this.setState({
        testStatus: 'questions',
        testType: testType,
        registrationStatus: registrationStatus,
        userId: userId,
        userEmail: userEmail,
        hanziType: hanziType,
        pastQuestionsList: [],
        timestampStartTest: Date.now() / 1000 | 0,
        numberOfQuestions: 1,
        testPhase: 0
      }, 
      () => {this.props.setTestStatus(this.state.testStatus)}
    );
  }
  
  /* update the user state after an answer */
  updateUserAfterAnswer(userLevel, numberOfQuestions, pastQuestionsList) {
    const isPhaseFinished = numberOfQuestions > MAX_NUMBER_QUESTIONS[this.state.testPhase];
    const isTestTotallyFinished = numberOfQuestions > MAX_NUMBER_QUESTIONS[MAX_NUMBER_QUESTIONS.length-1];

    this.setState({
        testStatus: isPhaseFinished ? (isTestTotallyFinished ? 'results' : 'doyoucontinue' ) : 'questions',
        userLevel: userLevel,
        numberOfQuestions: 1+this.state.numberOfQuestions,
        pastQuestionsList: pastQuestionsList
      },
      () => {this.props.setTestStatus(this.state.testStatus)}
    );

    
  }

  /* update the user state after an answer, hanzi test */
  updateUserAfterAnswerHanzi(userLevelHandwriting, userLevelPinyin, userLevelMeaning, numberOfQuestions, pastQuestionsList) {
    const isPhaseFinished = numberOfQuestions > MAX_NUMBER_QUESTIONS_HANZI[this.state.testPhase];
    const isTestTotallyFinished = numberOfQuestions > MAX_NUMBER_QUESTIONS_HANZI[MAX_NUMBER_QUESTIONS_HANZI.length-1]

    this.setState({
        testStatus: isPhaseFinished ? (isTestTotallyFinished ? 'results' : 'doyoucontinue' ) : 'questions',
        userLevelHandwriting: userLevelHandwriting,
        userLevelPinyin: userLevelPinyin,
        userLevelMeaning: userLevelMeaning,
        numberOfQuestions: 1+this.state.numberOfQuestions,
        pastQuestionsList: pastQuestionsList
      },
      () => {this.props.setTestStatus(this.state.testStatus)}
    );
  }

    /* update the user state after an answer */
  updateUserAfterAnswerChengyu(userLevel, numberOfQuestions, pastQuestionsList) {
    const isPhaseFinished = numberOfQuestions > MAX_NUMBER_QUESTIONS_CHENGYU[this.state.testPhase];
    const isTestTotallyFinished = numberOfQuestions > MAX_NUMBER_QUESTIONS_CHENGYU[MAX_NUMBER_QUESTIONS_CHENGYU.length-1];

    this.setState({
        testStatus: isPhaseFinished ? (isTestTotallyFinished ? 'results' : 'doyoucontinue' ) : 'questions',
        userLevel: userLevel,
        numberOfQuestions: 1+this.state.numberOfQuestions,
        pastQuestionsList: pastQuestionsList
      },
      () => {this.props.setTestStatus(this.state.testStatus)}
    );
  }

  continueOrResults(e, doyoucontinue) {
    this.setState({
        testStatus: doyoucontinue ? 'questions' : 'sandwich',
        maxNumberOfQuestions: this.state.testType==='vocab' ? MAX_NUMBER_QUESTIONS[this.state.testPhase+1]
                                : (
                                    this.state.testType==='hanzi' ? 
                                      MAX_NUMBER_QUESTIONS_HANZI[this.state.testPhase+1]
                                    : MAX_NUMBER_QUESTIONS_CHENGYU[this.state.testPhase+1]
                                  ),
        testPhase: 1+this.state.testPhase
      }, 
      () => {this.props.setTestStatus(this.state.testStatus)}
    );    
  }

  seeResults(e) {
    this.setState({testStatus: 'results'}, 
      () => {this.props.setTestStatus(this.state.testStatus)}
    );    
  }

  /* Restart the test */
  restartTheTest(e, registrationStatus, userId, testType) {
    if(testType === 'vocab') {
      // Create the log-likelihood array of length 114, filled with zeros
      let userLevel = [];
      userLevel.length = 114;
      userLevel.fill(0);
      this.setState({
        userLevel: userLevel,
        maxNumberOfQuestions: MAX_NUMBER_QUESTIONS[0]
      });
    }
    else if(testType === 'hanzi') {
      let userLevelHandwriting = [];
      let userLevelPinyin = [];
      let userLevelMeaning = [];
      userLevelHandwriting.length = 100;
      userLevelHandwriting.fill(0);
      userLevelPinyin.length = 100;
      userLevelPinyin.fill(0);
      userLevelMeaning.length = 100;
      userLevelMeaning.fill(0);
      this.setState({
        userLevelHandwriting: userLevelHandwriting,
        userLevelPinyin: userLevelPinyin,
        userLevelMeaning: userLevelMeaning,
        maxNumberOfQuestions: MAX_NUMBER_QUESTIONS_HANZI[0],
      });
    }
    else {
      // Chengyus: Create the log-likelihood array of length 119, filled with zeros (1, 10, 20, 30, 40, 50, 75, 100, 150, ..., 5650)
      let userLevel = [];
      userLevel.length = 119;
      userLevel.fill(0);
      this.setState({
        userLevel: userLevel,
        maxNumberOfQuestions: MAX_NUMBER_QUESTIONS_CHENGYU[0]
      });
    }

    // Change the page to "questions" and create session data
    this.setState({
        testStatus: 'questions',
        registrationStatus: registrationStatus,
        userId: userId,
        timestampStartTest: Date.now() / 1000 | 0,
        numberOfQuestions: 1,
        pastQuestionsList: [],
        testPhase: 0,
        testType: testType
      },
      () => {this.props.setTestStatus(this.state.testStatus)}
    );
  }

}


export default LevelTest
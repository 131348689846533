import React from 'react';

class DoyoucontinueWords extends React.Component {

	render() {

		return (
		<div className="container">
			<div className="alert alert-primary">
				<h4>You{"'"}ve completed the test.</h4>
				<p>
					You can either check your results now or continue the test to get a more precise estimate of your level.
				</p>
				<div>
	              <button className="btn btn-secondary btn-doyoucontinue" type="button" onClick={(e) => this.props.onContinueOrResults(e, false)} >
	                See my results
	              </button>
	              <button className="btn btn-primary btn-doyoucontinue" type="button" onClick={(e) => this.props.onContinueOrResults(e, true)} >
	                Continue the test
	              </button>
	            </div>
			</div>
		</div>
		);
	}
}


export default DoyoucontinueWords
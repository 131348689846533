import React from 'react';

class SandwichPage extends React.Component {

	render() {

		return (
		<div className="container">
			<div className="alert alert-primary">
				<h4>Chinese Grammar Gems</h4>
				<p>
                Discover the intricacies of Mandarin grammar with my <em>"Chinese Grammar Gems"</em> weekly newsletter.
				</p>
				<div>
                    <button className="btn btn-primary btn-doyoucontinue" type="button">
                        <a href="https://hsklevel.substack.com" role="button" onClick={(e) => this.props.onSeeResults(e)}
                            target="_blank" rel="noreferrer noopener" style={{color:'white', textDecoration: 'none'}}>
                            Read it now
                        </a>
                    </button>
                    <p>Check it out now and return to your results immediately.</p>
	            </div>
			</div>
		</div>
		);
	}
}


export default SandwichPage
import React from 'react';
import axios from 'axios';

import { API_PATH_SHARERESULTS_CHENGYU } from '../constants';



class ShareButton extends React.Component {
	constructor(props) {
		super(props);

		this.state = {hasShared: false};
	}

	shareResults(e) {
    // Copy to clipboard and save the source of the share in the database

    	let text = 'My Chinese level statistics'
    	text += '\n\n';
    	text += 'I know ' + this.props.userKnownChengyu + ' chengyu (成语) in Chinese';
    	text += '\n\n';
    	text += 'Take the test on www.hsklevel.com';

    	navigator.clipboard.writeText(text);

	    axios({
	      method: 'post',
	      url: `${API_PATH_SHARERESULTS_CHENGYU}`,
	      headers: {'content-type': 'application/json'},
	      data: {userId: this.props.userId}
	    })
	    .then(result => {
		  this.setState({hasShared: true});
		})
	    .catch(error => {});
	}


	render() {  

		return (
		<div>
	        <button className="btn btn-success" style={{margin:'5px 5px 5px 5px', fontSize:'22px'}} type="button"
	        		onClick={(e) => this.shareResults(e)}>
	          Share your results
	        </button>
		    {this.state.hasShared && <p><em>Copied results to clipboard</em></p>}
        </div>
		);
	}
}


export default ShareButton
import React from 'react';

class Maintenance extends React.Component {

	render() {

		return (
		<div className="container">
			<div className="alert alert-primary">
				<h4>HSKlevel is currently being upgraded</h4>
				<p>Try again in a few hours!</p>
				<p>In the meantime, let's learn how to say this in Chinese: </p>
				<strong>网站正在升级中</strong>
				<p><em>Wǎngzhàn zhèngzài shēngjí zhōng</em></p>
				<ul>
					<li><strong>网站</strong> (<em>wǎngzhàn</em>): website</li>
					<li><strong>正在</strong> (<em>zhèngzài</em>): grammatical particle expressing actions in progress</li>
					<li><strong>升级</strong> (<em>shēngjí</em>): to upgrade</li>
					<li><strong>中</strong> (<em>zhōng</em>): in (the process of)</li>
				</ul>
				Follow me on social media for more Chinese learning: <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hskLevel" className="icon fa-instagram"><span className="label">Instagram</span></a>, <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hsklevel" className="icon fa-twitter"><span className="label">Twitter</span></a>, <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/hskLevel" className="icon fa-facebook"><span className="label">Facebook</span></a>, <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@hsklevel" className="icon fa-youtube"><span className="label">YouTube</span></a>.
				
			
			</div>
		</div>
		);
	}
}


export default Maintenance
import React from 'react'
import axios from 'axios';

import { API_PATH_CHOOSE_QUESTION, API_PATH_SAVE_ANSWER } from '../constants';



function encouragement(progress, maxProgress) {
  let txt = `Question ${progress} / ${maxProgress}`;
  return txt;
}

class ProgressBar extends React.Component {
  render() {
    const progressInPercents = 100*this.props.numberOfQuestions/this.props.maxNumberOfQuestions;
    return (
        <div>
          <div>
            <p><em>{encouragement(this.props.numberOfQuestions, this.props.maxNumberOfQuestions)}</em></p>
          </div>
          <div className="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                  style={{width: progressInPercents +'%'}}
                  aria-valuenow={progressInPercents} aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
      );
  }
}


/* Question component */
class Question extends React.Component {
  constructor(props) {
    super(props);

    this.state = {isAnswerShown: false, isAnswerClicked: false, timestampStartQuestion: Date.now() / 1000, error: ''};
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }

  render() {
    const error = this.props.error;
    let errorMessage = <p><em>Connection error: {error} {this.state.error}</em></p>;
    if((error === null || error === '') && (this.state.error === null || this.state.error === '')) errorMessage = '';

    //const answers = this.props.answers;
    const word = this.props.word;
    const pinyin = this.props.pinyin;
    const meanings = this.props.meaning1 + ( (this.props.meaning2 !== '' && this.props.meaning2 !== null) ? ', '+this.props.meaning2 : '') + ( (this.props.meaning3 !== '' && this.props.meaning3 !== null) ? ', '+this.props.meaning3 : '');

    return (
      <div>

        <ProgressBar numberOfQuestions={this.props.numberOfQuestions} maxNumberOfQuestions={this.props.maxNumberOfQuestions} />

        <div>{errorMessage}</div>

        <form className="form-answers">
            {!this.state.isAnswerShown &&
              <div className="question">
                Do you know the word <strong style={{fontSize: '2rem'}}>{word}</strong> ?
              </div>
            }
            {this.state.isAnswerShown &&
              <div className="question">
                Did you know the answer?
              </div>
            }
            

            {this.state.isAnswerShown &&
              <div>
                <div className="alert alert-primary" role="alert">
                  <strong>{word}</strong> <em>({pinyin})</em><br />{meanings}
                </div>

                <div>
                  <button className="btn btn-danger btn-answer" type="button" onClick={(e) => this.nextQuestion(e, 'no')} disabled={this.state.isAnswerClicked}>
                    No
                  </button>
                  <button className="btn btn-secondary btn-answer" type="button" onClick={(e) => this.nextQuestion(e, 'soso')} disabled={this.state.isAnswerClicked}>
                    So-so
                  </button>
                  <button className="btn btn-success btn-answer" type="button" onClick={(e) => this.nextQuestion(e, 'yes')} disabled={this.state.isAnswerClicked}>
                    Yes
                  </button>
                </div>

                {this.props.isFirstQuestion && // If this is the first question, explain the button "so-so"
                  <div className="alert alert-info" role="alert">
                    <ul className="list-inline" style={{textAlign: 'left'}}>
                      <li><strong>Yes</strong>: you can use the word</li>
                      <li><strong>So-so</strong>: you can understand the word in context</li>
                      <li><strong>No</strong>: otherwise, even if you could infer the meaning</li>
                    </ul>
                  </div>
                }

              </div>
            }

            {!this.state.isAnswerShown && 
              <button className="btn btn-primary btn-nextquestion" type="button" onClick={(e) => this.showAnswer(e)}>
                Show the answer
              </button>
            }

        </form>

      </div>
      );
  }


  showAnswer(e) {
    this.setState({
      isAnswerShown: true
    });
  }


  nextQuestion(e, answer) {

    this.setState({
      isAnswerClicked: true
    });

    const answerTimeLength = (Date.now() / 1000) - this.state.timestampStartQuestion;
    const isFirstQuestion = this.props.isFirstQuestion;
    const isRightAnswer = (answer==='yes') ? 1 : ( (answer==='soso') ? 0.5 : 0);
    axios({
      method: 'post',
      url: `${API_PATH_SAVE_ANSWER}`,
      headers: {'content-type': 'application/json'},
      data: {
        userId: this.props.userId,
        userLevel: this.props.userLevel,
        questionId: this.props.questionId,
        questionWordRank: this.props.questionWordRank,
        questionType: this.props.questionType,
        isRightAnswer: isRightAnswer,
        answerTimeLength: answerTimeLength,
        isFirstQuestion: isFirstQuestion
      }
    })
    .then(result => {
        const userLevelNew = result.data.userLevel;
        this.props.updateUserAfterAnswer(userLevelNew, this.props.numberOfQuestions+1, this.props.pastQuestionsList);
        this.props.selectWord(this.props.userId, userLevelNew, this.props.hanziType, this.props.numberOfQuestions, this.props.pastQuestionsList);
      })
    .then( () => {
      this.setState({
          isAnswerShown: false,
          isAnswerClicked: false,
          timestampStartQuestion: Date.now() / 1000
        });
      })
    .catch(error => {
        this.setState({error:error, timestampStartQuestion: Date.now() / 1000})
      });
  }

}




class QuestionsFormWords extends React.Component {
  constructor(props) {
    super(props);

    this.state = {word: '', questionId: -1, error: ''};

    this.selectWord = this.selectWord.bind(this);
    this.selectWord(this.props.userId, this.props.userLevel, this.props.hanziType, this.props.numberOfQuestions, this.props.pastQuestionsList);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }

  render() {
    return (
      <Question
        questionId={this.state.questionId}
        questionWordRank={this.state.questionWordRank}
        meaning1={this.state.meaning1}
        meaning2={this.state.meaning2}
        meaning3={this.state.meaning3}
        pinyin={this.state.pinyin}
        word={this.state.word}
        hanziType={this.props.hanziType}
        error={this.state.error}
        timestampStartTest={this.props.timestampStartTest}
        numberOfQuestions={this.props.numberOfQuestions}
        pastQuestionsList={this.state.pastQuestionsList}
        maxNumberOfQuestions={this.props.maxNumberOfQuestions}
        userId={this.props.userId}
        userLevel={this.props.userLevel}
        selectWord={this.selectWord}
        updateUserAfterAnswer={this.props.updateUserAfterAnswer}
        isFirstQuestion={this.props.numberOfQuestions === 1}
        questionType={this.state.questionType}
      />
    );
  }

  /* Call the API to select a question/word */
  selectWord(userId, userLevel, hanziType, numberOfQuestions, pastQuestionsList) {
    axios({
      method: 'post',
      url: `${API_PATH_CHOOSE_QUESTION}`,
      headers: {'content-type': 'application/json'},
      data: {
              userId: userId,
              userLevel: userLevel,
              hanziType: hanziType,
              numberOfQuestions: numberOfQuestions,
              pastQuestionsList: pastQuestionsList
            }
    })
    .then(result => {
        this.setState((state, props) => ({
          questionId: parseInt(result.data.questionId),
          questionWordRank: parseInt(result.data.questionWordRank),
          meaning1: result.data.meaning1,
          meaning2: result.data.meaning2,
          meaning3: result.data.meaning3,
          word: result.data.word,
          pinyin: result.data.pinyin,
          questionType: result.data.questionType,
          pastQuestionsList: result.data.pastQuestionsList
        }));
      })
    .catch(error => {
        this.setState({error:error, timestampStartQuestion: Date.now() / 1000})
      });
  }

}


export default QuestionsFormWords

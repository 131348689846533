import React from 'react';
import axios from 'axios';

import { ResponsivePie } from '@nivo/pie';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { ResponsiveLine } from '@nivo/line';
import { BoxLegendSvg } from "@nivo/legends";
import { ResponsiveRadar } from '@nivo/radar';

import { API_PATH_SAVE_RESULTS_HANZI,
  API_PATH_GET_PAST_RESULTS_HANZI,
  API_PATH_REGISTER,
  API_PATH_SAVEFEEDBACK_HANZI,
  API_PATH_SAVEAFFILIATE_HANZI,
  EVOLUTION_GRAPH_COLORS  } from '../constants';

import OfferMe from '../OfferMe';
import ShareButton from './ShareButton';
import RestartButton from '../RestartButton';



class DoughnutChart extends React.Component {

  render() {
    let data = [
      {
        "id": "Handwriting",
        "data": [
          {
            "x": "Known characters",
            "y": this.props.x_handwriting,
          },
          {
            "x": "Characters to learn",
            "y": this.props.total-this.props.x_handwriting
          }
        ]
      },
      {
        "id": "Pronunciation",
        "data": [
          {
            "x": "Known characters",
            "y": this.props.x_pinyin,
          },
          {
            "x": "Characters to learn",
            "y": this.props.total-this.props.x_pinyin
          }
        ]
      },
      {
        "id": "Understanding",
        "data": [
          {
            "x": "Known characters",
            "y": this.props.x_meaning,
          },
          {
            "x": "Characters to learn",
            "y": this.props.total-this.props.x_meaning
          }
        ]
      }
    ];

  const CustomLayerComponent = (myProps) => (layerProps) => {
    const [centerX, centerY] = layerProps.center;
    return (
      <text
        x={centerX}
        y={-20}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: "25px",
          fontWeight: "400"
        }}
      >
        {myProps}
      </text>
    );
  };

  const theme = {
      axis: {
        ticks: {
          text: {
            fontSize: '20px',
            fontWeight: '100'
          }
        },
      }
    };
    return (
      <div className="col-sm-12 col-md-6" style={{minHeight:'400px', minWidth:'300px'}}>
        <ResponsiveRadialBar
          data={data}
          margin={{ top: 60, right: 50, bottom: 50, left: 50 }}
          fit={false}
          innerRadius={0.2}
          colors={['#1F78B4', 'rgb(167,206,227)']}
          enableArcLinkLabels={false}
          enableArcLabels={false}
          isInteractive={false}
          circularAxisOuter={{ tickSize: 4, tickPadding: 15 }}
          layers={['grid', 'tracks', 'bars', 'labels', 'legends', CustomLayerComponent(this.props.title)]}
          theme={theme}
          padding={0.4}
          />
      </div>
    );
  }
}


class HanzisChartHSK extends React.Component {
  render() {
    const numberHanzis = [170, 170, 300, 460, 650, 1050];
    const charts = [1,2,3,4,5,6].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={'HSK '+lvl.toString()}
        x_handwriting={this.props.hanzisByLevel_handwriting[lvl-1]}
        x_pinyin={this.props.hanzisByLevel_pinyin[lvl-1]}
        x_meaning={this.props.hanzisByLevel_meaning[lvl-1]}
        total={numberHanzis[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'1000px'}}>
        {charts}
      </div>
    );
  }
}

class HanzisChartNewHSK extends React.Component {
  render() {
    const numberHanzis = [300, 300, 300, 300, 300, 300, 1200];
    const titles = ['HSK 1', 'HSK 2', 'HSK  3', 'HSK 4', 'HSK 5', 'HSK 6', 'HSK 7-9'];
    const charts = [1,2,3,4,5,6,7].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={titles[lvl-1]}
        x_handwriting={this.props.hanzisByLevel_handwriting[lvl-1]}
        x_pinyin={this.props.hanzisByLevel_pinyin[lvl-1]}
        x_meaning={this.props.hanzisByLevel_meaning[lvl-1]}
        total={numberHanzis[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'1333px'}}>
        {charts}
      </div>
    );
  }
}

class HanzisChartTOCFL extends React.Component {
  render() {
    const numberHanzis = [150, 160, 160, 300, 550, 660, 630];
    const titles  = ['Novice 1', 'Novice 2', 'Band A.1', 'Band A.2', 'Band B.1', 'Band B.2', 'Band  C.1'];
    const charts = [1,2,3,4,5,6,7].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={titles[lvl-1]}
        x_handwriting={this.props.hanzisByLevel_handwriting[lvl-1]}
        x_pinyin={this.props.hanzisByLevel_pinyin[lvl-1]}
        x_meaning={this.props.hanzisByLevel_meaning[lvl-1]}
        total={numberHanzis[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'1333px'}}>
        {charts}
      </div>
    );
  }
}


class HanzisChartStandardLevel extends React.Component {
  render() {
    const numberHanzis = [3500, 3000, 1605];
    const titles  = ['Tier I', 'Tier II', 'Tier III'];
    const charts = [1,2,3].map((lvl) =>
      <DoughnutChart
        key={lvl.toString()}
        title={titles[lvl-1]}
        x_handwriting={this.props.hanzisByLevel_handwriting[lvl-1]}
        x_pinyin={this.props.hanzisByLevel_pinyin[lvl-1]}
        x_meaning={this.props.hanzisByLevel_meaning[lvl-1]}
        total={numberHanzis[lvl-1]}
      />
    );
    return (
      <div className="row" style={{marginTop:'0px', minHeight:'1000px'}}>
        {charts}
      </div>
    );
  }
}



class EvolutionChart extends React.Component {
  render() {
    
    const data = this.props.hanzisByLevelByDate;
    const dates = this.props.dates;

    const legendTitles = ['HSK 1', 'HSK 2', 'HSK 3', 'HSK 4', 'HSK 5', "HSK 6", 'Non-HSK']
    let legendHSK = [0,1,2,3,4,5,6].map((lvl) => (
      <div className="col" style={{paddingn:0}}>
        <svg height="40px" width="40px">
          <circle cx="20" cy="20" r="9" fill={EVOLUTION_GRAPH_COLORS[lvl]} />
        </svg><br />
        <span style={{whiteSpace: "nowrap"}}>{legendTitles[lvl]}</span>
      </div>
      )
    );

    return (
      <div className="container">
        <div className="row justify-content-center" style={{marginTop:'0px', paddingTop:'10px', minHeight:'70vh', minWidth: 0, width:'99%'}}>
         <h5>{this.props.title}</h5>
          <div className="col" style={{marginTop:'0px', paddingTop:'10px', minHeight:'70vh', minWidth: 0, width:'99%'}}>
            <ResponsiveLine
              data={data}
              margin={{ top: 10, right: 5, bottom: 70, left: 90 }}
              xScale={{ format: "%m/%d/%Y %H:%M:%S", type: "time" }}
              xFormat="time:%m/%d/%Y %H:%M:%S"
              yScale={{
                  type: 'linear',
                  min: 1,
                  max: 'auto',
                  stacked: false,
                  reverse: false
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -30,
                  tickValues: 5,
                  format: "%b %Y",
                  legend: '',
                  legendOffset: 60,
                  legendPosition: 'middle'
              }}
              axisLeft={{
                  orient: 'left',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: '',
                  legendOffset: -50,
                  legendPosition: 'middle'
              }}
              pointSize={0}
              enableArea={true}
              areaOpacity={1}
              //curve="step"
              //curve="catmullRom"
              isInteractive={false}
              useMesh={false}
              colors={EVOLUTION_GRAPH_COLORS}
              theme={{ axis: {ticks: {text: {fontSize: 20}}}, legends: { text: { fontSize: 20 } } }}
            />
          </div>
        <div className="container text-center" style={{padding:'0px'}}>
          <div className="row justify-content-center" style={{margin:'auto', maxWidth:'90%'}}>
            {legendHSK}
          </div>
        </div>
        </div>
      </div>
    );
  }
}

class FrequencyChart extends React.Component {

  render() {

    const theme = {
      axis: {
        ticks: {
          text: {
            fontSize: '20px',
            fontWeight: '400'
          }
        },
      },
      grid: {
        line: {
          stroke: "rgba(0, 0, 0, .30)",
          strokeDasharray: "6 4"
        }
      },
      dots: {
        text: {
          fontSize: '20px',
          fontWeight: '100'
        }
      }
    };

    const frequencies = [{'title': 'Understanding', 'value': this.props.frequency_meaning},
                         {'title': 'Pronunciation', 'value': this.props.frequency_pinyin},
                         {'title': 'Handwriting', 'value': this.props.frequency_handwriting}];
    
    return (
      <div className="container text-center">
        <div className="row justify-content-center" style={{marginTop:'10px', padding:'5%', height:'50vh', minHeight:'300px', minWidth: 0}}>
          <div className="col" style={{minWidth: 0}}>
            <ResponsiveRadar
              margin={{ top: 20, left: 115, right: 115}}
              data={frequencies}
              indexBy="title"
              keys={["value"]}
              colors={"#2490D9"}
              borderWidth={0}
              maxValue={100}
              theme={theme}
              isInteractive={false}
              fillOpacity={0.6}
              dotSize={0}
              dotLabel={function (e){return e.value+'%'}}
              enableDotLabel={true}
              dotLabelYOffset={0}
              gridShape="linear"
            />
          </div>
        </div>
      </div>
    );
  }
}




class Affiliate extends React.Component {

  affiliate(e) {
    // Save the affiliation in the database
    axios({
      method: 'post',
      url: `${API_PATH_SAVEAFFILIATE_HANZI}`,
      headers: {'content-type': 'application/json'},
      data: {userId: this.props.userId, affiliateBrand:this.props.affiliateBrand}
    })
    .catch(error => {});
  }

  render() {
    return (
      <>
        {this.props.affiliateBrand === 'maayot' &&
          <div className="alert alert-primary">
            <strong>Our special recommendation for you:</strong>
            <button className="btn btn-primary" style={{margin:'5px 5px 5px 5px', fontSize:'22px'}} type="button">
                <a href={"https://www.maayot.com?via=hsk"} role="button"
                  onClick={(e) => this.affiliate(e)} target="_blank" rel="noreferrer noopener" style={{fontSize:'22px', color:'white'}}>
                     Click here to boost your <em>Chinese reading</em> with stories
                </a>
            </button>
          </div>
        }
        {this.props.affiliateBrand === 'fluentu' &&
          <div className="alert alert-primary">
            <strong>Our special recommendation for you:</strong>
            <button className="btn btn-primary" style={{margin:'5px 5px 5px 5px', fontSize:'22px'}} type="button">
                <a href={"https://www.fluentu.com/?rfsn=5723753.c21670b&subid=results"} role="button"
                  onClick={(e) => this.affiliate(e)} target="_blank" rel="noreferrer noopener" style={{fontSize:'22px', color:'white'}}>
                     Click here to learn Chinese with real-world videos
                </a>
            </button>
          </div>
        }
      </>
    );
  }
}


class ResultsHanzi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      knownHanzis_handwriting: 0,
      knownHanzis_pinyin: 0,
      knownHanzis_meaning: 0,
      hanzisByLevel_handwriting: [0,0,0,0,0,0],
      hanzisByNewLevel_handwriting: [0,0,0,0,0,0,0],
      hanzisByTOCFLLevel_handwriting: [0,0,0,0,0,0,0],
      hanzisByStandardLevel_handwriting: [0,0,0],
      hanzisByLevel_pinyin: [0,0,0,0,0,0],
      hanzisByNewLevel_pinyin: [0,0,0,0,0,0,0],
      hanzisByTOCFLLevel_pinyin: [0,0,0,0,0,0,0],
      hanzisByStandardLevel_pinyin: [0,0,0],
      hanzisByLevel_meaning: [0,0,0,0,0,0],
      hanzisByNewLevel_meaning: [0,0,0,0,0,0,0],
      hanzisByTOCFLLevel_meaning: [0,0,0,0,0,0,0],
      hanzisByStandardLevel_meaning: [0,0,0],
      frequency_handwriting: 0,
      frequency_pinyin: 0,
      frequency_meaning: 0,
      isNewUser: true,
      dates: [],
      hanzisByLevelByDate_handwriting: [[],[],[],[],[],[]],
      hanzisByLevelByDate_pinyin: [[],[],[],[],[],[]],
      hanzisByLevelByDate_meaning: [[],[],[],[],[],[]],
      givenFeedback: null,
      showAffiliate: false
    };
  }

  componentDidMount() {
    /* Compute the number of known hanzis
    Save the results in the database */
    axios({
      method: 'post',
      url: `${API_PATH_SAVE_RESULTS_HANZI}`,
      headers: {'content-type': 'application/json'},
      data: {
        userId: this.props.userId,
        userLevelHandwriting: this.props.userLevelHandwriting,
        userLevelPinyin: this.props.userLevelPinyin,
        userLevelMeaning: this.props.userLevelMeaning
      }
    })
    .then(result => {
        this.setState({
          knownHanzis_handwriting: result.data.knownHanzis_handwriting,
          knownHanzis_pinyin: result.data.knownHanzis_pinyin,
          knownHanzis_meaning: result.data.knownHanzis_meaning,
          hanzisByLevel_handwriting: result.data.hanzisByLevel_handwriting,
          hanzisByNewLevel_handwriting: result.data.hanzisByNewLevel_handwriting,
          hanzisByTOCFLLevel_handwriting: result.data.hanzisByTOCFLLevel_handwriting,
          hanzisByStandardLevel_handwriting: result.data.hanzisByStandardLevel_handwriting,
          hanzisByLevel_pinyin: result.data.hanzisByLevel_pinyin,
          hanzisByNewLevel_pinyin: result.data.hanzisByNewLevel_pinyin,
          hanzisByTOCFLLevel_pinyin: result.data.hanzisByTOCFLLevel_pinyin,
          hanzisByStandardLevel_pinyin: result.data.hanzisByStandardLevel_pinyin,
          hanzisByLevel_meaning: result.data.hanzisByLevel_meaning,
          hanzisByNewLevel_meaning: result.data.hanzisByNewLevel_meaning,
          hanzisByTOCFLLevel_meaning: result.data.hanzisByTOCFLLevel_meaning,
          hanzisByStandardLevel_meaning: result.data.hanzisByStandardLevel_meaning,
          frequency_handwriting: result.data.frequency_handwriting,
          frequency_pinyin: result.data.frequency_pinyin,
          frequency_meaning: result.data.frequency_meaning
        });
        
        /* Check if the user is new, and if not, return its past results */
        axios({
          method: 'post',
          url: `${API_PATH_GET_PAST_RESULTS_HANZI}`,
          headers: {'content-type': 'application/json'},
          data: {
            userEmail: this.props.userEmail
          }
        })
        .then(result => {
            const today = new Date();
            const firstseen = new Date(result.data.dates[0]);
            this.setState({
              isNewUser: result.data.isNewUser,
              dates: result.data.dates,
              hanzisByLevelByDate_handwriting: result.data.hanzisByLevelByDate_handwriting,
              hanzisByLevelByDate_pinyin: result.data.hanzisByLevelByDate_pinyin,
              hanzisByLevelByDate_meaning: result.data.hanzisByLevelByDate_meaning,
              showAffiliate: today-firstseen < 1000*3600*24
            });
          })
        .catch(error => {
            this.setState({error:error})
          });
      })
    .catch(error => {
        this.setState({error:error})
      });
  }


  feedback(e, feedbackAnswer) {
    // Save the feedback in the database
    feedbackAnswer = (feedbackAnswer==='yes');
    axios({
      method: 'post',
      url: `${API_PATH_SAVEFEEDBACK_HANZI}`,
      headers: {'content-type': 'application/json'},
      data: {userId: this.props.userId, feedbackAnswer: feedbackAnswer}
    })
    .then(result => {
      this.setState({givenFeedback: feedbackAnswer});
    })
    .catch(error => {});
  }


  render() {
    const TOCFLTitles = ['Novice 1', 'Novice 2', 'Band A.1', 'Band A.2', 'Band B.1', 'Band B.2', 'Band C.1'];
    const newHSKTitles = ['HSK 1', 'HSK 2', 'HSK 3', 'HSK 4', 'HSK 5', 'HSK 6', 'HSK 7-9'];
    //const affiliateBrand = (this.props.userId % 2 === 0) ? 'maayot' : 'fluentu';
    //const affiliateBrand = 'maayot';

    return (
      <div className="container">

        <div className="alert span5 alert-primary">
                
          <h4>Your Results</h4>
            <ul>
              <li><em>Understanding</em>: 
                You can understand <strong>{this.state.knownHanzis_meaning===9750 ? "more than 10000" : this.state.knownHanzis_meaning}</strong> character{this.state.knownHanzis_meaning>1 ? 's' : ''}
              </li>
              <li><em>Pronunciation</em>: 
                You can pronounce <strong>{this.state.knownHanzis_pinyin===9750 ? "more than 10000" : this.state.knownHanzis_pinyin}</strong> character{this.state.knownHanzis_pinyin>1 ? 's' : ''}
              </li>
              <li><em>Handwriting</em>: 
                You can handwrite <strong>{this.state.knownHanzis_handwriting===9750 ? "more than 10000" : this.state.knownHanzis_handwriting}</strong> character{this.state.knownHanzis_handwriting>1 ? 's' : ''}
              </li>
            </ul>

          <p><em>(Scroll down to see all the details about your level)</em></p>

          <ShareButton userId={this.props.userId}
            userKnownHanzis_handwriting={this.state.knownHanzis_handwriting}
            userKnownHanzis_pinyin={this.state.knownHanzis_pinyin}
            userKnownHanzis_meaning={this.state.knownHanzis_meaning} />

          <div>Do you think the test is accurate for you?
            {this.state.givenFeedback === null &&
              <div>
                <button className="btn btn-primary" style={{margin:'0 5px 0 5px'}} type="button" onClick={(e) => this.feedback(e, 'yes')} disabled={this.state.givenFeedback!==null}>Yes</button>
                <button className="btn btn-primary" style={{margin:'0 5px 0 5px'}} type="button" onClick={(e) => this.feedback(e, 'no')} disabled={this.state.givenFeedback!==null}>No</button>
              </div>
            }
            {this.state.givenFeedback && <span><br/><em>Great!</em> <span role="img" aria-label="happy">☺️</span></span>}
            {this.state.givenFeedback===false &&
              <><br/>
                <em> Uh-oh </em><span role="img" aria-label="oops">😬</span><em>, try to retake the test</em>
                <RestartButton
                  userEmail={this.props.userEmail}
                  hanziType={this.props.hanziType}
                  onRestartTheTest={this.props.onRestartTheTest}
                  testType={'hanzi'}
                />
              </>
            }
          </div>
        </div>

        {/*
          this.state.showAffiliate &&
          <Affiliate userId={this.props.userId} affiliateBrand={affiliateBrand} />
          */
        }

        <div className="alert alert-info">
          <strong>You can also test your vocabulary and chengyu</strong>
          <br />
          <em>Click only when you{"'"}re finished checking your results</em>
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'vocab'}
            title={'Take the vocabulary test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'chengyu'}
            title={'Take the chengyu test'}
          />
        </div>

        <OfferMe userId={this.props.userId} hanziType={this.props.hanziType} testType='hanzi' />
        
        
        <h2 style={{marginTop:'70px'}}>1. Your character knowledge by HSK 2.0 level</h2>
        {this.props.hanziType === 'trad' && <p><em><span role="img" aria-label="warning">⚠️</span>The HSK test uses simplified Chinese characters!</em></p>}
        <HanzisChartHSK hanzisByLevel_handwriting={this.state.hanzisByLevel_handwriting}
                        hanzisByLevel_pinyin={this.state.hanzisByLevel_pinyin}
                        hanzisByLevel_meaning={this.state.hanzisByLevel_meaning} />


        <h2 style={{marginTop:'70px'}}>2. Your character knowledge by HSK 3.0 level</h2>
        {this.props.hanziType === 'trad' && <p><em><span role="img" aria-label="warning">⚠️</span>The HSK test uses simplified Chinese characters!</em></p>}
        <HanzisChartNewHSK hanzisByLevel_handwriting={this.state.hanzisByNewLevel_handwriting}
                           hanzisByLevel_pinyin={this.state.hanzisByNewLevel_pinyin}
                           hanzisByLevel_meaning={this.state.hanzisByNewLevel_meaning} />


        <h2 style={{marginTop:'70px'}}>3. Your character knowledge by TOCFL level</h2>
        {this.props.hanziType === 'simp' && <p><em><span role="img" aria-label="warning">⚠️</span>The TOCFL test uses traditional Chinese characters!</em></p>}
        <HanzisChartTOCFL hanzisByLevel_handwriting={this.state.hanzisByTOCFLLevel_handwriting}
                          hanzisByLevel_pinyin={this.state.hanzisByTOCFLLevel_pinyin}
                          hanzisByLevel_meaning={this.state.hanzisByTOCFLLevel_meaning} />

        <h2 style={{marginTop:'70px'}}>4. The <em>Table of General Standard Chinese Characters</em></h2>
        <p><em>The Table of General Standard Chinese Characters (通用规范汉字表) is a standard list of 8105 characters published by the Chinese government and divided into three sublists:</em></p>
        <ul>
          <li><em>Tier I: frequently used characters (3500 characters)</em></li>
          <li><em>Tier II: commonly used characters (3000 characters)</em></li>
          <li><em>Tier III: characters commonly used as names and terminology (1605 characters)</em></li>
        </ul>
        {this.props.hanziType === 'trad' && <p><em><span role="img" aria-label="warning">⚠️</span>The Table of <em>General Standard Chinese Characters</em> is an official documet of the People's Republic of China and is therefore made of simplified characters.</em></p>}
        <HanzisChartStandardLevel hanzisByLevel_handwriting={this.state.hanzisByStandardLevel_handwriting}
                          hanzisByLevel_pinyin={this.state.hanzisByStandardLevel_pinyin}
                          hanzisByLevel_meaning={this.state.hanzisByStandardLevel_meaning} />

        
        <h2 style={{marginTop:'70px'}}>5. Your character knowledge as frequencies</h2>
        <p><em>{"That's how much you can understand, pronounce and handwrite from a typical Chinese text:"}</em></p>
        <FrequencyChart
          frequency_handwriting={this.state.frequency_handwriting}
          frequency_pinyin={this.state.frequency_pinyin}
          frequency_meaning={this.state.frequency_meaning}
        />

        {!this.state.isNewUser &&
          <div style={{marginTop:'70px'}}>
            <h2>6. Your progression</h2>
            <EvolutionChart
              dates={this.state.dates}
              title={'Evolution of your understanding skills'}
              hanzisByLevelByDate={this.state.hanzisByLevelByDate_meaning}
            />
            <br /><br />
            <EvolutionChart
              dates={this.state.dates}
              title={'Evolution of your pronunciation skills'}
              hanzisByLevelByDate={this.state.hanzisByLevelByDate_pinyin}
            />
            <br /><br />
            <EvolutionChart
              dates={this.state.dates}
              title={'Evolution of your handwriting skills'}
              hanzisByLevelByDate={this.state.hanzisByLevelByDate_handwriting}
            />
          </div>
        }
        <div style={{marginTop:'70px'}}></div>


        {/*
          this.state.showAffiliate &&
          <Affiliate userId={this.props.userId} affiliateBrand={affiliateBrand} />
          */
        }

        <div>
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'hanzi'}
              title={'Retake the characters test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'vocab'}
            title={'Take the vocabulary test'}
          />
          <RestartButton
            userEmail={this.props.userEmail}
            hanziType={this.props.hanziType}
            onRestartTheTest={this.props.onRestartTheTest}
            testType={'chengyu'}
            title={'Take the chengyu test'}
          />
        </div>

      </div>
    );
  }


}


export default ResultsHanzi

import React from 'react';
import axios from 'axios';

import { API_PATH_REGISTER } from './constants';

import OfferMe from './OfferMe';



class RegisterForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = {email: '', hanziType:'simp', testType:'vocab'};

    this.startTheTest = this.startTheTest.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleHanziTypeChange = this.handleHanziTypeChange.bind(this);
    this.handleTestTypeChange = this.handleTestTypeChange.bind(this);
  }

  startTheTest(e) {
    e.preventDefault();

    // Register the user in the database
    axios({
        method: 'post',
        url: `${API_PATH_REGISTER}`,
        headers: {'content-type': 'application/json'},
        data: {email: this.state.email, hanziType: this.state.hanziType, testType: this.state.testType}
      })
    .then(result => {
        // Start the test
        const registrationStatus = result.data.registrationStatus;
        const userId = parseInt(result.data.userId);
        this.props.onStartTheTest(e, registrationStatus, userId, this.state.email, this.state.hanziType, this.state.testType);
      })
    .catch(error => {});
  }

  handleEmailChange(e) {
    this.setState({email: e.target.value});
  }

  handleHanziTypeChange(e) {
    this.setState({hanziType: e.target.value});
  }

  handleTestTypeChange(e) {
    this.setState({testType: e.target.value});
  }

  render() {
    const error = this.props.error;
    let errorMessage;
    if(error === 'invalidEmail') errorMessage = <p><em>Your email is invalid, please try again.</em></p>
    else if(error === 'connectionError') errorMessage = <p><em>Connection error, please try again later.</em></p>
    else if (error === 'emptyEmail') errorMessage = <p><em>Please enter your email address.</em></p>
    return (
      <div className="container row">
        <form className="form-signin">
          <div className="text-center mb-4">

            <h1 className="h3 mb-3 font-weight-normal">
              How many
              <span className="gradient-color">
                {this.state.testType==='vocab' ? ' words ' : this.state.testType==='hanzi' ? ' characters ' : ' chengyu '}
              </span>
              do I know in Chinese?
            </h1>
            
            <h5>I want to test my</h5>
            <div className="form-check form-check-inline" style={{marginBottom:"0px"}}>
              <input className="form-check-input" type="radio" name="testType" value="vocab" id="vocab" required
                checked={this.state.testType==='vocab'}
                onChange={this.handleTestTypeChange}
              />
              <label className="form-check-label" htmlFor="vocab">Vocabulary</label>
            </div>
            <br />
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="testType" value="hanzi" id="hanzi" required
                checked={this.state.testType==='hanzi'}
                onChange={this.handleTestTypeChange}
              />
              <label className="form-check-label" htmlFor="hanzi">Characters</label>
            </div>

            <div className="form-check form-check-inline" style={{marginBottom: '10px'}}>
              <input className="form-check-input" type="radio" name="testType" value="chengyu" id="chengyu" required
                checked={this.state.testType==='chengyu'}
                onChange={this.handleTestTypeChange}
              />
              <label className="form-check-label" htmlFor="chengyu">Chengyu</label>
            </div>

            <br />

            {/*<h5>Find out by taking the test</h5>*/}

            {errorMessage}

            <div className="form-label-group">
              <input type="email" name="email" id="email" placeholder="Email" className="form-control"
                      value={this.state.email} onChange={this.handleEmailChange} required autoFocus />
              <label htmlFor="email">Email</label>
            </div>

            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="hanziType" value="simp" id="simp" required
                checked={this.state.hanziType==='simp'}
                onChange={this.handleHanziTypeChange}
              />
              <label className="form-check-label" htmlFor="simp">Simplified</label>
            </div>

            <div className="form-check form-check-inline" style={{marginBottom: '10px'}}>
              <input className="form-check-input" type="radio" name="hanziType" value="trad" id="trad" required
                checked={this.state.hanziType==='trad'}
                onChange={this.handleHanziTypeChange}
              />
              <label className="form-check-label" htmlFor="trad">Traditional</label>
            </div>
            
            <button className="btn btn-lg btn-primary btn-block btn-startthetest" onClick={this.startTheTest}>Start the test</button>

            <div style={{marginTop:'20px'}}>
            <OfferMe hanziType={this.state.hanziType} />
            </div>

            <div className="mt-5 mb-3 text-left">
              <h5 className="text-center">Test your Chinese level online for free</h5>
              <ul>
                <li>Determine your <em>HSK</em> and <em>TOCFL levels</em></li>
                <li>Covers both <em>HSK 2.0</em> and <em>HSK 3.0 syllabuses</em></li>
                <li>Find out how many words and characters you know in Mandarin</li>
                <li>The only test to find out how many chengyu (成语) you know</li>
                <li>Fast and accurate AI-powered test</li>
              </ul>
            </div>
            
          </div>
        </form>
      </div>
    );
  }
}


export default RegisterForm

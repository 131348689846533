import React from 'react'
import axios from 'axios';

import { API_PATH_CHOOSE_QUESTION_HANZI, API_PATH_SAVE_ANSWER_HANZI, NOT_BMP_HANZIS_QUESTION_ID } from '../constants';



function encouragement(progress, maxProgress) {
  let txt = `Question ${progress} / ${maxProgress}`;
  return txt;
}

class ProgressBar extends React.Component {
  render() {
    const progressInPercents = 100*this.props.numberOfQuestions/this.props.maxNumberOfQuestions;
    return (
        <div>
          <div>
            <p><em>{encouragement(this.props.numberOfQuestions, this.props.maxNumberOfQuestions)}</em></p>
          </div>
          <div className="progress">
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                  style={{width: progressInPercents +'%'}}
                  aria-valuenow={progressInPercents} aria-valuemin="0" aria-valuemax="100">
            </div>
          </div>
        </div>
      );
  }
}


/* Question component */
class Question extends React.Component {
  constructor(props) {
    super(props);

    this.state = {isAnswerShown: false, isAnswerClicked: false, errorNotAnswered:false, timestampStartQuestion: Date.now() / 1000, error: ''};

    this.handleHandwritingChange = this.handleHandwritingChange.bind(this);
    this.handlePinyinChange = this.handlePinyinChange.bind(this);
    this.handleMeaningChange = this.handleMeaningChange.bind(this);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }

  handleHandwritingChange(e) {
    this.setState({
      handwriting: e.target.value,
      isAnswerClicked: e.target.value!=null && this.state.pinyin!=null && this.state.meaning!=null
    });
  }
  handlePinyinChange(e) {
    this.setState({
      pinyin: e.target.value,
      isAnswerClicked: e.target.value!=null && this.state.handwriting!=null && this.state.meaning!=null
    });
  }
  handleMeaningChange(e) {
    this.setState({
      meaning: e.target.value,
      isAnswerClicked: e.target.value!=null && this.state.handwriting!=null && this.state.pinyin!=null
    });
  }

  render() {

    const error = this.props.error;
    let errorMessage = <p><em>Connection error: {error} {this.state.error}</em></p>;
    if((error === null || error === '') && (this.state.error === null || this.state.error === '')) errorMessage = '';

    const hanzi = NOT_BMP_HANZIS_QUESTION_ID.includes(this.props.questionId) ?
                    <img src={require("../hanzis_pictures/"+this.props.questionId+"_"+this.props.hanziType+".png")} width="28px" />
                    :
                    this.props.hanzi;
    const pinyin = this.props.pinyin;
    const meanings = this.props.definition;

    return (
      <div>

        <ProgressBar numberOfQuestions={this.props.numberOfQuestions} maxNumberOfQuestions={this.props.maxNumberOfQuestions} />

        <div>{errorMessage}</div>

        <form className="form-answers">
            {!this.state.isAnswerShown &&
              <div className="question">
                Do you know the character <strong style={{fontSize: '2rem'}}>{hanzi}</strong> ?
              </div>
            }            

            {this.state.isAnswerShown &&
              <div>
                <div className="alert alert-primary" role="alert">
                  <strong>{hanzi}</strong> <em>({pinyin})</em><br />{meanings}
                </div>

                {this.state.errorNotAnswered &&
                  <div className="alert alert-danger" role="alert">
                    Please answer all three questions.
                  </div>
                }
                <div>

                <p className="questionHanzi">
                  Did you know the above definition of <strong>{hanzi}</strong>?
                </p>
                <div className="row" style={{marginBottom:'3px'}}>
                  {/*<div className="col">Meaning</div>*/}
                  <div className="btn-group col-7 mx-auto" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check" name="btnradio-meaning" value="meaningNo" id="meaningNo" autoComplete="off" required
                      checked={this.state.meaning==='meaningNo'}
                      onChange={this.handleMeaningChange} />
                    <label className="btn btn-outline-primary" htmlFor="meaningNo">No</label>

                    <input type="radio" className="btn-check" name="btnradio-meaning" value="meaningSoso" id="meaningSoso" autoComplete="off" required
                      checked={this.state.meaning==='meaningSoso'}
                      onChange={this.handleMeaningChange} />
                    <label className="btn btn-outline-primary" htmlFor="meaningSoso">So-so</label>

                    <input type="radio" className="btn-check" name="btnradio-meaning" value="meaningYes" id="meaningYes" autoComplete="off" required
                      checked={this.state.meaning==='meaningYes'}
                      onChange={this.handleMeaningChange} />
                    <label className="btn btn-outline-primary" htmlFor="meaningYes">Yes</label>
                  </div>
                </div>

                <p className="questionHanzi">
                  Did you know <strong>{hanzi}</strong> was pronounced <strong>{pinyin}</strong>?
                </p>
                <div className="row" style={{marginBottom:'3px'}}>
                  {/*<div className="col">Pinyin</div>*/}
                  <div className="btn-group col-7 mx-auto" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check" name="btnradio-pinyin" value="pinyinNo" id="pinyinNo" autoComplete="off" required
                      checked={this.state.pinyin==='pinyinNo'}
                      onChange={this.handlePinyinChange} />
                    <label className="btn btn-outline-primary" htmlFor="pinyinNo">No</label>

                    <input type="radio" className="btn-check" name="btnradio-pinyin" value="pinyinSoso" id="pinyinSoso" autoComplete="off" required
                      checked={this.state.pinyin==='pinyinSoso'}
                      onChange={this.handlePinyinChange} />
                    <label className="btn btn-outline-primary" htmlFor="pinyinSoso">So-so</label>

                    <input type="radio" className="btn-check" name="btnradio-pinyin" value="pinyinYes" id="pinyinYes" autoComplete="off" required
                      checked={this.state.pinyin==='pinyinYes'}
                      onChange={this.handlePinyinChange} />
                    <label className="btn btn-outline-primary" htmlFor="pinyinYes">Yes</label>
                  </div>
                </div>

                <p className="questionHanzi">
                  Could you handwrite <strong>{hanzi}</strong> without looking?
                </p>
                <div className="row">
                  {/*<div className="col">Handwriting</div>*/}
                  <div className="btn-group col-7 mx-auto" role="group" aria-label="Basic radio toggle button group">
                    <input type="radio" className="btn-check" name="btnradio-handwriting" value="handwritingNo" id="handwritingNo" autoComplete="off" required
                      checked={this.state.handwriting==='handwritingNo'}
                      onChange={this.handleHandwritingChange} />
                    <label className="btn btn-outline-primary" htmlFor="handwritingNo">No</label>

                    <input type="radio" className="btn-check" name="btnradio-handwriting" value="handwritingSoso" id="handwritingSoso" autoComplete="off" required 
                      checked={this.state.handwriting==='handwritingSoso'}
                      onChange={this.handleHandwritingChange} />
                    <label className="btn btn-outline-primary" htmlFor="handwritingSoso">So-so</label>

                    <input type="radio" className="btn-check" name="btnradio-handwriting" value="handwritingYes" id="handwritingYes" autoComplete="off" required
                      checked={this.state.handwriting==='handwritingYes'}
                      onChange={this.handleHandwritingChange} />
                    <label className="btn btn-outline-primary" htmlFor="handwritingYes">Yes</label>
                  </div>
                </div>
              </div>

              <div style={{marginTop:'15px', marginBottom:'50px'}}>
                <button className="btn btn-primary btn-nextquestion" type="button" onClick={(e) => this.nextQuestion(e, this.state.handwriting, this.state.pinyin, this.state.meaning)} disabled={!this.state.isAnswerClicked}>
                  Next question
                </button>
              </div>

            </div>
          }

            {!this.state.isAnswerShown && 
              <button className="btn btn-primary btn-nextquestion" type="button" onClick={(e) => this.showAnswer(e)}>
                Show the answer
              </button>
            }

        </form>

      </div>
      );
  }


  showAnswer(e) {
    this.setState({
      isAnswerShown: true
    });
  }


  nextQuestion(e) {
    const answerHandwriting = this.state.handwriting;
    const answerPinyin = this.state.pinyin;
    const answerMeaning = this.state.meaning;

    if([answerHandwriting, answerPinyin, answerMeaning].includes(undefined) 
      || [answerHandwriting, answerPinyin, answerMeaning].includes(null)) {
      // If all answers haven't been checked, we display an error message
      this.setState({
        isAnswerClicked: false,
        errorNotAnswered: true
      });
    }
    else {
      const answerTimeLength = (Date.now() / 1000) - this.state.timestampStartQuestion;
      const isFirstQuestion = this.props.isFirstQuestion;

      const isRightAnswerHandwriting = (answerHandwriting==='handwritingYes') ? 1 : ( (answerHandwriting==='handwritingSoso') ? 0.5 : 0);
      const isRightAnswerPinyin = (answerPinyin==='pinyinYes') ? 1 : ( (answerPinyin==='pinyinSoso') ? 0.5 : 0);
      const isRightAnswerMeaning = (answerMeaning==='meaningYes') ? 1 : ( (answerMeaning==='meaningSoso') ? 0.5 : 0);

      axios({
        method: 'post',
        url: `${API_PATH_SAVE_ANSWER_HANZI}`,
        headers: {'content-type': 'application/json'},
        data: {
          userId: this.props.userId,
          userLevelHandwriting: this.props.userLevelHandwriting,
          userLevelPinyin: this.props.userLevelPinyin,
          userLevelMeaning: this.props.userLevelMeaning,
          questionId: this.props.questionId,
          questionHanziRank: this.props.questionHanziRank,
          questionType: this.props.questionType,
          isRightAnswerHandwriting: isRightAnswerHandwriting,
          isRightAnswerPinyin: isRightAnswerPinyin,
          isRightAnswerMeaning: isRightAnswerMeaning,
          answerTimeLength: answerTimeLength,
          isFirstQuestion: isFirstQuestion
        }
      })
      .then(result => {
          const userLevelHandwritingNew = result.data.userLevelHandwriting;
          const userLevelPinyinNew = result.data.userLevelPinyin;
          const userLevelMeaningNew = result.data.userLevelMeaning;

          this.props.updateUserAfterAnswerHanzi(userLevelHandwritingNew, userLevelPinyinNew, userLevelMeaningNew, this.props.numberOfQuestions+1, this.props.pastQuestionsList);
          this.props.selectHanzi(this.props.userId, userLevelHandwritingNew, userLevelPinyinNew, userLevelMeaningNew, this.props.hanziType, this.props.numberOfQuestions, this.props.pastQuestionsList);
        })
      .then( () => {
        this.setState({
            isAnswerShown: false,
            isAnswerClicked: false,
            handwriting: null,
            pinyin: null,
            meaning: null,
            timestampStartQuestion: Date.now() / 1000
          });
        })
      .catch(error => {
          this.setState({error:error, timestampStartQuestion: Date.now() / 1000})
        });
    }
  }
}




class QuestionsFormHanzi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {hanzi: '', questionId: -1, error: ''};

    this.selectHanzi = this.selectHanzi.bind(this);
    this.selectHanzi(this.props.userId, this.props.userLevelHandwriting, this.props.userLevelPinyin, this.props.userLevelMeaning, this.props.hanziType, this.props.numberOfQuestions, this.props.pastQuestionsList);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state,callback)=>{
        return;
    };
  }

  render() {
    return (
      <Question
        questionId={this.state.questionId}
        questionHanziRank={this.state.questionHanziRank}
        definition={this.state.definition}
        pinyin={this.state.pinyin}
        hanzi={this.state.hanzi}
        hanziType={this.props.hanziType}
        error={this.state.error}
        timestampStartTest={this.props.timestampStartTest}
        numberOfQuestions={this.props.numberOfQuestions}
        pastQuestionsList={this.state.pastQuestionsList}
        maxNumberOfQuestions={this.props.maxNumberOfQuestions}
        userId={this.props.userId}
        userLevelHandwriting={this.props.userLevelHandwriting} 
        userLevelPinyin={this.props.userLevelPinyin} 
        userLevelMeaning={this.props.userLevelMeaning} 
        selectHanzi={this.selectHanzi}
        updateUserAfterAnswerHanzi={this.props.updateUserAfterAnswerHanzi}
        isFirstQuestion={this.props.numberOfQuestions === 1}
        questionType={this.state.questionType}
      />
    );
  }

  /* Call the API to select a question/Hanzi */
  selectHanzi(userId, userLevelHandwriting, userLevelPinyin, userLevelMeaning, hanziType, numberOfQuestions, pastQuestionsList) {
    axios({
      method: 'post',
      url: `${API_PATH_CHOOSE_QUESTION_HANZI}`,
      headers: {'content-type': 'application/json'},
      data: {
              userId: userId,
              userLevelHandwriting: userLevelHandwriting,
              userLevelPinyin: userLevelPinyin,
              userLevelMeaning: userLevelMeaning,
              hanziType: hanziType,
              numberOfQuestions: numberOfQuestions,
              pastQuestionsList: pastQuestionsList
            }
    })
    .then(result => {
        this.setState((state, props) => ({
          questionId: parseInt(result.data.questionId),
          questionHanziRank: parseInt(result.data.questionHanziRank),
          definition: result.data.definition,
          hanzi: result.data.hanzi,
          pinyin: result.data.pinyin,
          questionType: result.data.questionType,
          pastQuestionsList: result.data.pastQuestionsList
        }));
      });
  }

}


export default QuestionsFormHanzi

import React from 'react';
import axios from 'axios';

import { API_PATH_REGISTER } from './constants';

class RestartButton extends React.Component {
  constructor(props) {
    super(props);

    this.restartTheTest = this.restartTheTest.bind(this);
  }

  render() {
    return(
      <form>
          <button className="btn btn-primary" onClick={(e) => this.restartTheTest(e, this.props.testType)} style={{margin:'5px 5px 5px 5px', fontSize:'22px'}}>
            {this.props.title == null ? 'Retake the test' : this.props.title}
          </button>
      </form>
    );
  }

  restartTheTest(e, testType) {
    e.preventDefault();
    // Register the user in the database
    axios({
        method: 'post',
        url: `${API_PATH_REGISTER}`,
        headers: {'content-type': 'application/json'},
        data: {email: this.props.userEmail, hanziType: this.props.hanziType, testType: testType}
      })
    .then(result => {
        // Start the test
        const registrationStatus = result.data.registrationStatus;
        const userId = parseInt(result.data.userId);
        this.props.onRestartTheTest(e, registrationStatus, userId, testType);
      })
    .catch(error => {});
  }
}

export default RestartButton
const isLocal = false;

const path = isLocal ? 'http://localhost:8881/HSKlevel/hsklevel/api/' : 'https://www.hsklevel.com/api/';

// Numerical Constants
export const MAX_NUMBER_QUESTIONS = [65,100,150,200,250,300];
export const MAX_NUMBER_QUESTIONS_HANZI = [50,75,100,150,200,250,300];
export const MAX_NUMBER_QUESTIONS_CHENGYU = [50,75,100,150,200,250,300];

// Colors
export const EVOLUTION_GRAPH_COLORS = ['#0D2F46', '#14476A', '#19608F', '#1F78B4', '#2490D9', '#47A3E1', '#DCD9D9'];

// Register.js
export const API_PATH_REGISTER = path + 'register.php';

/////////////////
// Question.js //
/////////////////
// Vocabulary
export const API_PATH_CHOOSE_QUESTION = path + 'Vocabulary/chooseQuestion.php';
export const API_PATH_SAVE_ANSWER = path + 'Vocabulary/saveAnswer.php';
// Hanzi
export const API_PATH_CHOOSE_QUESTION_HANZI = path + 'Hanzi/chooseQuestion.php';
export const API_PATH_SAVE_ANSWER_HANZI = path + 'Hanzi/saveAnswer.php';
export const NOT_BMP_HANZIS_QUESTION_ID = [8459, 8469, 8472, 8480, 8481, 8486, 8492, 8496, 8500, 8502, 8504,
       8508, 8512, 8522, 8524, 8528, 8529, 8541, 8544, 8549, 8555, 8560,
       8568, 8571, 8583, 8586, 8590, 8592, 8595, 8599, 8603, 8604, 8613,
       8624, 8629, 8631, 8643, 8647, 8654, 8656, 8671, 8675, 8696, 8706,
       8708, 8711, 8714, 8722, 8732, 8737, 8767, 8768, 8775, 8781, 8793,
       8800, 8801, 8805, 8816, 8824, 8825, 8830, 8831, 8836, 8848, 8858,
       8861, 8862, 8868, 8875, 8876, 8886, 8897, 8915, 8930, 8943, 8957,
       8958, 8968, 8976, 8987, 9003, 9030, 9037, 9043, 9054, 9055, 9058,
       9059, 9063, 9068, 9071, 9081, 9084, 9090, 9091, 9098, 9099, 9103,
       9107, 9116, 9138, 9139, 9140, 9141, 9142, 9144, 9151, 9155, 9162,
       9176, 9180, 9181, 9182, 9183, 9185, 9189, 9198, 9204, 9222, 9227,
       9230, 9239, 9241, 9249, 9254, 9259, 9264, 9271, 9279, 9282, 9285,
       9291, 9295, 9298, 9300, 9302, 9309, 9312, 9313, 9314, 9315, 9328,
       9345, 9353, 9376, 9380, 9384, 9390, 9412, 9419, 9425, 9427, 9436,
       9437, 9446, 9449, 9458, 9459, 9464, 9467, 9475, 9485, 9486, 9498,
       9499, 9517, 9522, 9527, 9539, 9543, 9546, 9550, 9551, 9557, 9574,
       9585, 9587, 9595, 9609, 9610, 9623, 9624, 9628, 9631, 9632, 9635,
       9643, 9655, 9658, 9661, 9670, 9679, 9686, 9688, 9704];
// Chengyu
export const API_PATH_CHOOSE_QUESTION_CHENGYU = path + 'Chengyu/chooseQuestion.php';
export const API_PATH_SAVE_ANSWER_CHENGYU = path + 'Chengyu/saveAnswer.php';

////////////////
// Results.js //
////////////////
// Vocabulary
export const API_PATH_SAVE_RESULTS = path + 'Vocabulary/saveResults.php';
export const API_PATH_GET_PAST_RESULTS = path + 'Vocabulary/getPastResults.php';
export const API_PATH_SHARERESULTS = path + 'Vocabulary/saveShareResults.php';
export const API_PATH_SAVEFEEDBACK = path + 'Vocabulary/saveFeedback.php';
export const API_PATH_SAVEOFFERME = path + 'Vocabulary/saveOfferMe.php';
export const API_PATH_SAVEAFFILIATE = path + 'Vocabulary/saveAffiliate.php';
// Hanzi
export const API_PATH_SAVE_RESULTS_HANZI = path + 'Hanzi/saveResults.php';
export const API_PATH_GET_PAST_RESULTS_HANZI = path + 'Hanzi/getPastResults.php';
export const API_PATH_SHARERESULTS_HANZI = path + 'Hanzi/saveShareResults.php';
export const API_PATH_SAVEFEEDBACK_HANZI = path + 'Hanzi/saveFeedback.php';
export const API_PATH_SAVEOFFERME_HANZI = path + 'Hanzi/saveOfferMe.php';
export const API_PATH_SAVEAFFILIATE_HANZI = path + 'Hanzi/saveAffiliate.php';
// Chengyu
export const API_PATH_SAVE_RESULTS_CHENGYU = path + 'Chengyu/saveResults.php';
export const API_PATH_GET_PAST_RESULTS_CHENGYU = path + 'Chengyu/getPastResults.php';
export const API_PATH_SHARERESULTS_CHENGYU = path + 'Chengyu/saveShareResults.php';
export const API_PATH_SAVEFEEDBACK_CHENGYU = path + 'Chengyu/saveFeedback.php';
export const API_PATH_SAVEOFFERME_CHENGYU = path + 'Chengyu/saveOfferMe.php';
export const API_PATH_SAVEAFFILIATE_CHENGYU = path + 'Chengyu/saveAffiliate.php';


/////////////////
// Learning.js //
/////////////////

export const API_PATH_CHOOSE_QUESTION_LEARNING = path + 'Learning/chooseQuestion.php';
export const API_PATH_SAVE_ANSWER_LEARNING = path + 'Learning/saveAnswer.php';

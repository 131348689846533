import React from 'react';


class About extends React.Component {

	render() {

		return (
		<div className="container">
			<p className="text-justify">
				Hi there, my name is <a target="_blank" rel="noopener noreferrer" href="https://francoispierrepaty.github.io">Pierre</a>, and I am a 27-year-old French engineer and polyglot.
				I have been studying Chinese since I was 12 years old.
				I started learning Mandarin in school, but when I visited China in 2011, I realized that my level was not as good as I thought.
				So, after returning to France, I started studying Mandarin on my own and passed the HSK 4 test in April 2012.
				Later, I attended university and studied math and physics, but did not have much time or motivation to continue studying Chinese.
				In 2018, I began a PhD in artificial intelligence and wanted to pick up Chinese again.
				However, after so many years without studying, I wanted to assess my level.
				That's how I came up with the idea for the HSK level test, which I have been developing and improving over the past few years.
				Besides Chinese and my native French, I speak English and Italian.
				You can follow me on <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/hskLevel" className="icon fa-instagram"><span className="label">Instagram</span></a>, <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/hsklevel" className="icon fa-twitter"><span className="label">Twitter</span></a>, <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/hskLevel" className="icon fa-facebook"><span className="label">Facebook</span></a>, <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@hsklevel" className="icon fa-youtube"><span className="label">YouTube</span></a> or send me an email at  <a target="_blank" rel="noopener noreferrer" href="mailto:nihao@hsklevel.com" className="icon alt fa-envelope"><span className="label">Email</span></a>.
			</p>
			<img src="francoispierre.jpg" width="200px" className="logo" />


			<h2></h2>
		</div>
		);
	}
}


export default About
import React from 'react';

// import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';

// import ReactPixel from 'react-facebook-pixel';

import LevelTest from './LevelTest/LevelTest';
// import Learning from './Learning/Learning';
import Maintenance from './Maintenance';
import About from './About';


class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentApp: 'home', // home, levelTest, learning, maintenance, about
      testStatus: null,
      showHeader: true
    };
    
    this.changeShowHeader = this.changeShowHeader.bind(this);
    this.setTestStatus = this.setTestStatus.bind(this);
  }

  changeShowHeader(showHeader) {
    this.setState({
      showHeader: showHeader
    });
  }

  setTestStatus(testStatus) {
    this.setState({
      testStatus: testStatus
    });
  }

  render() {
    const currentApp = this.state.currentApp;
    //const currentApp = 'maintenance'; REMEMBER TO CHANGE HEADER IN STATE
    let page;
    if(currentApp === 'home') { // Home --> levelTest
      page = <LevelTest changeShowHeader={this.changeShowHeader} setTestStatus={this.setTestStatus} />;
    }
    else if(currentApp === 'levelTest') {
      page = <LevelTest changeShowHeader={this.changeShowHeader} setTestStatus={this.setTestStatus} />;
    }
    // else if(currentApp === 'learning') {
    //   page = <Learning />;
    // }
    else if(currentApp === 'maintenance') {
      page = <Maintenance />;
    }
    else if(currentApp === 'about') {
      page = <About />;
    }
    else { // By default, show the test
      page = <LevelTest changeShowHeader={this.changeShowHeader} />;
    }

    return (
      <div>
        {/*
          <div className="masthead-brand">
            <img src="logo.png" width="100px" alt="HSK level" className="logo" />
          </div>
        */}
          <main>
            <header className="mb-auto">
              {
                this.state.showHeader &&
                <div className="row">
                  <h3 className="float-md-start mb-0">HSK level</h3>
                  <nav className="nav nav-masthead justify-content-center float-md-end">
                    <a className={['home', 'levelTest'].includes(currentApp) ? "nav-link fw-bold py-1 px-0 active" : "nav-link fw-bold py-1 px-0"} href="#" aria-current={['home', 'levelTest'].includes(currentApp) ? "page" : undefined} onClick={(e)=>this.setState({currentApp:'home'})}>Home</a>
                    <a className="nav-link fw-bold py-1 px-0" href="https://hsklevel.substack.com" target="_blank" rel="noreferrer noopener">Lessons</a>
                    {/* <a className={currentApp==='learning' ? "nav-link fw-bold py-1 px-0 active" : "nav-link fw-bold py-1 px-0"} href="#" aria-current={currentApp==='learning' ? "page" : undefined} onClick={(e)=>this.setState({currentApp:'learning'})}>Learning</a> */}
                    <a className={currentApp==='about' ? "nav-link fw-bold py-1 px-0 active" : "nav-link fw-bold py-1 px-0"} aria-current={currentApp==='about' ? "page" : undefined} href="#" onClick={(e)=>this.setState({currentApp:'about'})}>About</a>
                  </nav>
                </div>
              }
              <div className="row">
                  <div className="col" style={{margin:'auto'}}>
                    <img src="logo.png" width="100px" alt="HSK level" className="logo" />
                  </div>
              </div>
            </header>
            {page}
          </main>
      </div>
    );
  }

  /* Google Analytics */
  componentDidMount() {
    // ReactGA.initialize('UA-131349707-1');
    // ReactGA.pageview('/');
    ReactGA4.initialize('G-7VFZNNXMXW');
    ReactGA4.send({ hitType: "pageview", page: "/" });

    // const advancedMatching = { }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    // const options = {
    //   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    //   debug: false, // enable logs
    // };
    // ReactPixel.init('1080677759503818', advancedMatching, options);
  }

  componentDidUpdate() {
    const route = ['home', 'levelTest'].includes(this.state.currentApp) ? 'levelTest/'+this.state.testStatus : this.state.currentApp;
    // ReactGA.pageview(route);
    ReactGA4.send({ hitType: "pageview", page: route });
  }

}


export default App